.highlight {
  background-color: #f0f2f5;
  color: #46596a;
  /*border: 1px solid #fff;
  */
  margin: 0 5px;
  padding: 3px;
  border-radius: 3px;
  margin-bottom: 2px;
}

.alert-me {
  display: grid;
  row-gap: 10px;
}
.alert-me-container {
}
.alert-me-dimension {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.summary-container {
  display: grid;
  row-gap: 10px;
}
.label {
  /* color: themeColors[secondaryColor], */
  font-size: "11px";
  font-weight: "500";
  color: #46596a;
  margin-bottom: 5px;
}
.logic {
  color: #267ee6;
  margin: 0 4px;
}
.criteria-formula {
  white-space: pre-wrap;
}
.summarystyle {
  height: "max-content";
  width: "100%";
  border: "1px solid #c5cfd9";
  padding: "7px";
  margin-top: "5px";
  border-radius: "4px";
  min-height: "24px";
  box-sizing: "border-box";
  overflow: "hidden";
  text-overflow: "ellipsis";
  background-color: "var(--secondaryColorLightest)";
  color: "var(--secondaryColor)";
}
