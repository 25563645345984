@import "../../../styles/sass/variables.scss";

.standalone-chart-types-wrapper {
  width: 100%;
  box-sizing: border-box;
  height: max-content;
}

.standalone-chart-wrapper-non-pivot {
  padding: 5px calc(#{$masterPadding} + 5px) 10px calc(#{$masterPadding} + 5px);
  height: 100%;
  box-sizing: border-box;

  .standalone-chart-outer-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    background-color: $popoverBgColor;
    box-shadow: $timeFiltersBoxShadow;
    margin-top: 10px;
    padding: 20px 10px;
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // transition: all 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .standalone-chart-container {
      width: 100%;
      height: 100%;

      &.no-height {
        height: 0%;
      }
    }

    &.expanded {
      height: calc(100% - 184px - 10px); //according height + margin
    }

    &.collapsed {
      height: calc(100% - 48px - 10px); //according height + margin
    }
  }
}

.standalone-chart-data-selections-wrapper-non-pivot {
  display: flex;
  align-items: center;
  width: 100%;

  .left-panel {
    width: calc(100% - 450px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .left-panel-row {
      display: flex;
      align-items: center;

      &.padded-left {
        padding-left: 5px;
      }

      &.padded-right {
        padding-right: 5px;
      }

      &.right-aligned {
        justify-content: flex-end;
      }

      &.sort-menu-bar {
        display: flex;
        justify-content: space-between;
        padding: 0px 5px;

        .standalone-menu-bar {
          display: flex;
        }
      }

      .info-title {
        display: inline-block;
        height: 24px !important;
        font-size: 10px;
        font-family: inherit;
        color: $secondaryColorLight !important;
        margin: 0px !important;
        // height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .row-title {
        margin-right: 10px !important;
        padding: 0px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 10px;
        color: $secondaryColor;
      }
    }
  }
  .right-panel {
    width: 450px;
    box-sizing: border-box;
  }
}
