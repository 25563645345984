@import "../../../styles/sass/variables.scss";

.sigview-multi-select-dnd-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  width: 100%;
  border: 1px solid $secondaryColorLighter;
  border-radius: 3px;
  box-sizing: border-box;

  .sigview-multi-select-dnd-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    .sigview-multi-select-dnd-title {
      font-size: 12px;
      color: $secondaryColor;
    }

    .sigview-multi-select-dnd-values-popover-icon {
      font-size: 16px !important;
      padding: 0px !important;
      color: $secondaryColor;
      margin-left: 0px !important;
      cursor: pointer;
    }
  }
  .sigview-multi-select-dnd-dnd-container {
    display: flex;
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
    height: 150px;
    overflow: auto;
  }

  .sigview-multi-select-dnd-values-clear-all-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px !important;
    padding: 0px !important;
    color: $secondaryColorLight;
    cursor: pointer;

    // cannot use classes hidden or invisible because CSS from angular overwrites
    &.react-invisible {
      // visibility: hidden;
      color: transparent;
      pointer-events: none;
    }

    &:hover {
      color: $failureColor !important;
    }
  }

  &.vertical {
    .sigview-multi-select-dnd-title-container {
      padding: 7px;
      margin-bottom: 5px;
      background-color: $secondaryColorLightest;
      border-radius: 2px 2px 0px 0px;
    }

    .sigview-multi-select-dnd-title-popovericon-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .sigview-multi-select-dnd-values-popover-icon {
      margin-right: 3px;
    }
  }

  &.horizontal {
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border: none;
    padding: 0px 5px;

    .sigview-multi-select-dnd-values-clear-all-icon {
      box-sizing: border-box;
      margin-left: 0px !important;
      border: 1px solid $secondaryColorLighter;
      border-left: 1px solid transparent;
      height: 30px;
      width: 30px;
      border-radius: 0px 3px 3px 0px;
    }

    .sigview-multi-select-dnd-title-container {
      padding: 0px;
      width: 100px;
      box-sizing: border-box;

      &.no-title {
        width: 35px;
      }

      .sigview-multi-select-dnd-title {
        // margin-right: 10px;
        box-sizing: border-box;
        width: 60px;

        &.no-title {
          width: 0px;
        }
      }

      .sigview-multi-select-dnd-values-popover-icon {
        border: 1px solid $secondaryColorLighter;
        border-radius: 3px 0px 0px 3px;
        height: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
      }
    }

    .sigview-multi-select-dnd-dnd-container {
      box-sizing: border-box;
      height: 30px;
      width: calc(100% - 95px);
      align-items: center;
      border-top: 1px solid $secondaryColorLighter;
      border-bottom: 1px solid $secondaryColorLighter;

      &.no-title {
        width: calc(100% - 35px);
      }
    }
  }
}

.sigview-multi-select-dnd-container-report {
  display: flex;
  flex-direction: column;
  width: 400px;
  width: 100%;
  border: 1px solid #edf1ff;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;

  .sigview-multi-select-dnd-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    .sigview-multi-select-dnd-title {
      font-size: 12px;
      color: $secondaryColor;
    }

    .sigview-multi-select-dnd-values-popover-icon {
      font-size: 16px !important;
      padding: 0px !important;
      color: $primaryColor;
      margin-left: 0px !important;
      cursor: pointer;
    }
  }

  .sigview-multi-select-dnd-dnd-container {
    display: flex;
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
    height: 150px;
    overflow: auto;
    margin: 5px 0px;
  }

  .sigview-multi-select-dnd-values-clear-all-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px !important;
    padding: 0px !important;
    color: $secondaryColorLight;
    cursor: pointer;

    // cannot use classes hidden or invisible because CSS from angular overwrites
    &.react-invisible {
      // visibility: hidden;
      color: transparent;
      pointer-events: none;
    }

    &:hover {
      color: $failureColor !important;
    }
  }

  &.vertical {
    .sigview-multi-select-dnd-title-container {
      padding: 7px;
      margin-bottom: 0px;
      background-color: #fff;
      border-radius: 2px 2px 0px 0px;
      border-bottom: 1px solid #edf1ff;
    }

    .sigview-multi-select-dnd-title-popovericon-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .sigview-multi-select-dnd-values-popover-icon {
      margin-right: 7px;
    }
  }

  &.horizontal {
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border: none;
    padding: 0px 5px;

    .sigview-multi-select-dnd-values-clear-all-icon {
      box-sizing: border-box;
      margin-left: 0px !important;
      border: 1px solid $secondaryColorLighter;
      border-left: 1px solid transparent;
      height: 30px;
      width: 30px;
      border-radius: 0px 3px 3px 0px;
    }

    .sigview-multi-select-dnd-title-container {
      padding: 0px;
      width: 100px;
      box-sizing: border-box;

      &.no-title {
        width: 35px;
      }

      .sigview-multi-select-dnd-title {
        // margin-right: 10px;
        box-sizing: border-box;
        width: 60px;

        &.no-title {
          width: 0px;
        }
      }

      .sigview-multi-select-dnd-values-popover-icon {
        border: 1px solid $secondaryColorLighter;
        border-radius: 3px 0px 0px 3px;
        height: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
      }
    }

    .sigview-multi-select-dnd-dnd-container {
      box-sizing: border-box;
      height: 30px;
      width: calc(100% - 95px);
      align-items: center;
      border-top: 1px solid $secondaryColorLighter;
      border-bottom: 1px solid $secondaryColorLighter;

      &.no-title {
        width: calc(100% - 35px);
      }
    }
  }
}

.sigview-multi-select-dnd-values-popup-container {
  width: 250px;
  background-color: $popoverBgColor;

  .sigview-multi-select-dnd-values-popup-container-title {
    margin: 0px !important;
    font-size: 12px;
    color: $secondaryColor;
    padding: 10px;
  }

  .sigview-multi-select-dnd-autocomplete-container {
    height: 230px;
    padding: 0px 10px 10px 10px;
  }

  .sigview-multi-select-dnd-autocomplete-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.quantity-selector-option-title {
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $secondaryColor;
  text-transform: capitalize;
}

.info-icon {
  font-size: 13px !important;
  margin-left: 5px;
  visibility: visible;
  color: $secondaryColorLighter;

  &:hover {
    color: $secondaryColor;
    cursor: pointer;
  }
}
