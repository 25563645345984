@import "../../../styles/sass/variables.scss";

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $footerHeight;
  // background-color: $secondaryColorLightest;
  background-color: #fff;
  box-shadow: $dsItemBoxShadow;

  .footer-text {
    // color: $secondaryColor;
    color: $secondaryColor;
    font-size: 10px;

    .sigmoid-redirect {
      text-decoration: none;
      // color: $primaryColor;
      color: $secondaryColor;
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
