@import "../../../styles/sass/variables.scss";

.filters-menu-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // height: 100%;
  // padding: 0px 0px 5px 0px; //Adding 5px to align with datastory below
  // padding: 0px calc(#{$masterPadding} + 5px); // create pivot padding
  // background-color: transparent !important;
  background-color: $mainContentBgColor;

  .left-panel,
  .right-panel {
    height: 100%;
  }

  .left-panel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .applied-filters-row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      min-width: 60px;

      .applied-filters-row-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        margin: 3px 0px 3px 0px !important;
        border-radius: 3px;
        height: 25px;
        box-sizing: border-box;
        color: red;
        font-family: inherit;
        font-size: 10px;
        min-width: 80px !important;
      }
    }
  }

  .right-panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 60px;
    margin-left: 5px;
  }
}

.add-filters-icon {
  height: 18px !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px !important;
  cursor: pointer;
  color: $headerBgColor;
  background-color: $primaryColor;
  border-radius: 3px;
  width: 18px;
  //height: 24px;
  justify-content: center;

  &:hover {
    color: $secondaryColorLightest !important;
    background-color: $primaryColor;
  }
}
