@import "../../../styles/sass/variables.scss";

.menu-bar-container {
  .view-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
