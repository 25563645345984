//Body

//Header
$headerHeight: 28px;

//Footer
$footerHeight: 20px;

//Sidenav
$sidenavWidth: 50px;

//Breadcrumbs
$breadcrumbsHeight: 30px;

//TimeMenuBar
$timeMenuBarHeight: 46px;
$filtersMenuBarHeight: 35px;

//Padding
$masterPadding: 17px;

//App

//Filters

//Colors
//COMMON
//PRIMARY
$primaryColor: var(--primaryColor);
$primaryColorLight: var(--primaryColorLight);
$primaryColorLighter: var(--primaryColorLighter);
$primaryColorLightest: var(--primaryColorLightest);

//SECONDARY
$secondaryColor: var(--secondaryColor);
$secondaryColorLight: var(--secondaryColorLight);
$secondaryColorLighter: var(--secondaryColorLighter);
$secondaryColorLightest: var(--secondaryColorLightest);
$tableRowBGColor: var(--tableRowBGColor);

//HEADER
$headerBgColor: var(--headerBgColor);
$headerBoxShadow: var(--headerBoxShadow);

//USER INFO
$userInfoBgColor: var(--userInfoBgColor);

//SIDENAV
$sidenavBgColor: var(--sidenavBgColor);
$sidenavItemColor: var(--sidenavItemColor);
$sidenavItemHoverColor: var(--sidenavItemHoverColor);

//MAIN CONTENT
$mainContentBgColor: var(--mainContentBgColor);

//DATASTORY
$dsItemBgColor: var(--dsItemBgColor);
$dsItemBoxShadow: var(--dsItemBoxShadow);

//TIME FILTERS
$timeFiltersBgColor: var(--timeFiltersBgColor);
$timeFiltersBoxShadow: var(--timeFiltersBoxShadow);

//BUTTONS
$buttonBgColor: var(--buttonBgColor);
$buttonBgColorLight: var(--buttonBgColorLight);
$buttonBgColorLighter: var(--buttonBgColorLighter);
$buttonBgColorLightest: var(--buttonBgColorLightest);
$buttonColor: var(--buttonColor);
$buttonColorLight: var(--buttonColorLight);
$buttonColorLighter: var(--buttonColorLighter);
$buttonColorLightest: var(--buttonColorLightest);
$buttonColorDark: var(--buttonColorDark);
$buttonColorDarker: var(--buttonColorDarker);
$buttonColorDarkest: var(--buttonColorDarkest);
$buttonDisabledBgColor: var(--buttonDisabledBgColor);
$buttonDisabledColor: var(--buttonDisabledColor);

//RADIO
$radioBgColor: var(--radioBgColor);
$radioColor: var(--radioColor);
$radioDisabledBgColor: var(--radioDisabledBgColor);
$radioDisabledColor: var(--radioDisabledColor);

//CHECKBOX
$checkboxColor: var(--checkboxColor);

//METRICS
$negChangeColor: var(--negChangeColor);
$posChangeColor: var(--posChangeColor);

//OTHERS
$popoverBgColor: var(--popoverBgColor);
$failureColor: var(--failureColor);
$overlayBgColor: var(--overlayBgColor);
$overlayBgColorLight: var(--overlayBgColorLight);
