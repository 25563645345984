@import "../../../styles/sass/variables.scss";

nav {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: $masterPadding;

  .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 12px;
    height: 100%;
    cursor: pointer;
    color: $secondaryColor;
    box-sizing: border-box;
    text-decoration: none !important;

    &:first-child {
      padding-left: 0px !important;
    }

    .nav-item-name-container {
      display: flex;
      justify-content: center;
      height: 28px;
      box-sizing: border-box;

      .nav-item-name-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .nav-item-name-top-bar {
          height: 3px;
          background-color: transparent;
          box-sizing: border-box;
          width: 100%;
        }

        .nav-item-name-bottom-bar {
          height: 3px;
          background-color: transparent;
          box-sizing: border-box;
          border-radius: 10px;
          width: 75%;
        }

        p.nav-item-name {
          display: flex;
          align-items: center;
          height: 22px;
          font-size: 13px;
        }
      }

      p.nav-item-post-name {
        display: flex;
        font-size: 10px;
        align-items: flex-start;
        height: 100%;
        margin-left: 1px !important;
        padding-top: 3px;
      }
    }

    &.active,
    &:hover {
      color: $primaryColor;

      .nav-item-name-bottom-bar {
        background-color: $primaryColor !important;
      }

      p.nav-item-name {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}
