@import "../../../styles/sass/variables.scss";

.wrapper-container {
  width: 100%;
  height: 100%;
  // padding: 12px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  &.horizontal {
    justify-content: flex-start;
  }
}

section.chart-filter-container.grid {
  display: grid;
  grid-template-columns: repeat(5, 70px);
  grid-auto-rows: 30px;
  grid-gap: 12px 15px;
  box-sizing: border-box;
}

section.chart-filter-container.horizontal {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 70px;
  grid-auto-rows: 30px;
  grid-gap: 12px 15px;
  box-sizing: border-box;
}

section.chart-filter-container.vertical {
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 70px;
  grid-auto-rows: 30px;
  grid-gap: 12px 15px;
  box-sizing: border-box;
}

section.chart-filter-container.horizontal::-webkit-scrollbar,
section.chart-filter-container.vertical::-webkit-scrollbar {
  display: none;
  box-sizing: border-box;
}

div.chart-icons-container {
  background: transparent;
  border: 1px solid $secondaryColorLighter;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  // background-color: white;
}

section.chart-filter-container {
  .icon-active {
    border: 1px solid $primaryColor;
    box-sizing: border-box;
  }
}

div.chart-icons-container:not(.filter-icon-disabled):hover {
  border: 1px solid $primaryColor;
  cursor: pointer;
  box-sizing: border-box;
}

// div.chart-icons-container.filter-icon-disabled {
//   pointer-events: none;
// }

section.chart-filter-container {
  .filter-icon-disabled {
    opacity: 0.5;
    box-sizing: border-box;
    background-color: transparent;
    cursor: not-allowed;
  }
}

.chart-type-popover-container {
  background-color: transparent;
  height: max-content;
  max-width: 200px;
  padding: 10px;
  font-size: 10px;
  color: $secondaryColor;

  .chart-type-popover-title {
    margin: 0px !important;
    margin-bottom: 8px !important;
  }

  .chart-type-popover-criteria-container {
    .chart-type-popover-criteria-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 3px !important;

      .criteria-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;

        &.dim {
          background-color: $primaryColor;
        }

        &.time-dim {
          background-color: $primaryColor;
          opacity: 0.7;
        }

        &.non-time-dim {
          background-color: $primaryColor;
          opacity: 0.3;
        }

        &.metric {
          background-color: $secondaryColor;
        }

        &.additive {
          background-color: $secondaryColor;
          opacity: 0.5;
        }
      }
    }
    .criteria-row-or-label {
      margin: 0px !important;
      color: $secondaryColorLighter;
    }
  }
}
