@import "../styles/sass/variables.scss";
// @import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round");
// @import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Fira+Sans");

@mixin scroll-box-shadow {
  // background: /* Shadow covers */ linear-gradient(
  //     white 30%,
  //     rgba(255, 255, 255, 0)
  //   ),
  //   linear-gradient(rgba(255, 255, 255, 0), white 96%) 0 100%,
  //   /* Shadows */
  //     radial-gradient(
  //       50% 0,
  //       farthest-side,
  //       rgba(0, 0, 0, 0.2),
  //       rgba(0, 0, 0, 0)
  //     ),
  //   radial-gradient(
  //       50% 100%,
  //       farthest-side,
  //       rgba(0, 0, 0, 0.2),
  //       rgba(0, 0, 0, 0)
  //     )
  //     0 100%;
  // background: /* Shadow covers */ linear-gradient(
  //     white 30%,
  //     rgba(255, 255, 255, 0)
  //   ),
  //   linear-gradient(rgba(255, 255, 255, 0), white 96%) 0 100%,
  //   /* Shadows */
  //     radial-gradient(
  //       farthest-side at 50% 0,
  //       rgba(0, 0, 0, 0.2),
  //       rgba(0, 0, 0, 0)
  //     ),
  //   radial-gradient(
  //       farthest-side at 50% 100%,
  //       rgba(0, 0, 0, 0.2),
  //       rgba(0, 0, 0, 0)
  //     )
  //     0 100%;
  // background-repeat: no-repeat;
  // background-color: white;
  // background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  // /* Opera doesn't support this in the shorthand */
  // background-attachment: local, local, scroll, scroll;
}

//REACT ROOT COMPONENT
.react-root-container {
  p {
    margin: 0px;
  }
}

//MAIN PAGE LAYOUT
// Moved it outside page so that it is at the top
section.dashboard-loader-container {
  position: fixed;
  z-index: 100000000000;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: $overlayBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  width: 100%;
  margin: 0px;
  font-family: "Fira Sans";
  font-size: 14px;
  font-weight: 400 !important;
  position: fixed !important; //Include to fix bug where react-joyride shifts it to the top

  p {
    margin: 0px;
  }

  &.layout-none {
    width: 100%;
    height: 100vh;
    background-color: $mainContentBgColor;

    main {
      width: 100%;
      height: 100vh;
      z-index: -1;
      section.content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
      }
    }
  }

  &.layout-top-bottom,
  &.layout-top-side-bottom {
    header {
      height: $headerHeight;
      box-sizing: border-box;
      z-index: 1;
    }

    main {
      z-index: -1;
      height: calc(100vh - #{$headerHeight} - #{$footerHeight});
      position: relative;

      section.content {
        background-color: $mainContentBgColor;

        section.main-content-data {
          height: calc(100vh - #{$headerHeight} - #{$footerHeight});
          overflow-y: auto !important;

          article.main-content-page-title {
            // background-color: red;
            padding: 25px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      section.content.time-menu-visible {
        section.time-menu-bar {
          height: $timeMenuBarHeight;
          box-sizing: border-box;
        }

        section.main-content-data {
          height: calc(
            100vh - #{$headerHeight} - #{$footerHeight} - #{$timeMenuBarHeight}
          );
          overflow-y: auto !important;
          background-color: $mainContentBgColor;
        }
      }

      section.content.filters-menu-visible {
        section.time-menu-bar {
          height: $timeMenuBarHeight;
          box-sizing: border-box;
        }

        section.filters-menu-bar.single {
          height: 38px;
          box-sizing: border-box;
        }
        section.filters-menu-bar.double {
          height: 76px;
          box-sizing: border-box;
        }

        &.single {
          section.main-content-data {
            height: calc(
              100vh - #{$headerHeight} - #{$footerHeight} - #{$timeMenuBarHeight} -
                38px
            );
            overflow-y: auto !important;
            background-color: $mainContentBgColor;
          }
        }

        &.double {
          section.main-content-data {
            height: calc(
              100vh - #{$headerHeight} - #{$footerHeight} - #{$timeMenuBarHeight} -
                96px
            );
            overflow-y: auto !important;
            background-color: $mainContentBgColor;
          }
        }
      }
    }

    footer {
      height: $footerHeight;
    }
  }

  &.layout-top-side-bottom {
    main {
      z-index: -1;
      display: flex;

      section.sidenav {
        width: $sidenavWidth;
        background-color: $sidenavBgColor;
      }

      section.content {
        width: calc(100% - #{$sidenavWidth});
        background-color: $mainContentBgColor;

        &.without-sidenav {
          width: 100%;
        }
      }
    }
  }

  &.layout-bottom {
    main {
      z-index: -1;
      height: calc(100vh - #{$footerHeight});
      position: relative;

      section.content {
        section.main-content-data {
          height: calc(100vh - 0px);
          overflow-y: auto !important;
        }
      }
    }

    footer {
      height: $footerHeight;
    }
  }
}

//############################################################################################

//OTHERS

//For hover on axes label, the values come up
g.xtick,
g.ytick {
  pointer-events: all;
}

//POPOVER
.sigview-hover-popup-content-container {
  font-size: 10px;
  font-family: "Fira Sans";
  padding: 10px;
  width: auto;
  max-width: 400px;
  background-color: $popoverBgColor;

  .sigview-hover-popup-title {
    margin: 0px 0px 10px 0px !important;
    border-bottom: 1px solid $secondaryColorLightest;
    padding-bottom: 3px;
    color: $primaryColor;
    text-transform: uppercase;
  }

  .sigview-hover-popup-content {
    .sigview-hover-popup-list-container {
      margin: 0px !important;
      padding-inline-start: 15px;
      color: $secondaryColor;
      list-style: auto !important;

      .sigview-hover-popup-list-item:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }
}

//SCROLLBAR
.selected-filters-container::-webkit-scrollbar,
.dimension-values-container::-webkit-scrollbar,
.reports-sidenav::-webkit-scrollbar,
.sigview-table-container::-webkit-scrollbar,
.report-manager-container .content-main::-webkit-scrollbar,
.advanced-filters-row-container::-webkit-scrollbar,
.sigview-multi-select-dnd-dnd-container::-webkit-scrollbar,
section.main-content-data::-webkit-scrollbar,
.chart-list-wrapper::-webkit-scrollbar,
.datastory-cat-content-wrapper::-webkit-scrollbar,
.sigview-styled-scroller::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

.selected-filters-container::-webkit-scrollbar-thumb,
.dimension-values-container::-webkit-scrollbar-thumb,
.reports-sidenav::-webkit-scrollbar-thumb,
.sigview-table-container::-webkit-scrollbar-thumb,
.report-manager-container .content-main::-webkit-scrollbar-thumb,
.advanced-filters-row-container::-webkit-scrollbar-thumb,
.sigview-multi-select-dnd-dnd-container::-webkit-scrollbar-thumb,
section.main-content-data::-webkit-scrollbar-thumb,
.chart-list-wrapper::-webkit-scrollbar-thumb,
.datastory-cat-content-wrapper::-webkit-scrollbar-thumb,
.sigview-styled-scroller::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  background-color: $secondaryColorLighter;
  border-radius: 5px;

  &:hover {
    background-color: $secondaryColorLight;
  }
}

.sigview-styled-scroller-thin.scroller-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sigview-styled-scroller-thin::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

.sigview-styled-scroller-thin::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: $secondaryColorLighter;
  border-radius: 3px;

  &:hover {
    background-color: $secondaryColorLight;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.sigview-rotate {
  animation: rotation 2s infinite linear;
}

//HIDDEN SCROLLBAR
.sigview-multi-select-dnd-dnd-container::-webkit-scrollbar {
  display: none;
}

.sigview-multi-select-dnd-dnd-container::-webkit-scrollbar-thumb {
  display: none;
}

//WILL BE REMOVED WHEN WE MOVE COMPLETELY TO REACT
.js-plotly-plot .plotly .modebar {
  background: transparent !important;
}

//COMMON CSS
.plot-container {
  height: 100%;
}
.react-material-icons {
  font-size: 20px !important;
}

// .datastory-cat-content-wrapper {
//   height: calc(100% - 65px - 35px - 25px - 25px);
//   overflow: auto;
// }

section.main-content-with-title {
  height: calc(100% - #{$breadcrumbsHeight});
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .no-data-image {
    width: 200px;
    height: 200px;
    background-image: url("https://storage.googleapis.com/sigview-icons/illustrations/noDatastory.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 15px;
  }

  .no-data {
    margin: 0px;
    width: 100%;
    text-align: center;
    color: $secondaryColorLight;
    font-size: 12px;
  }
}

.sigview-dialog-chart-clone {
  padding: 20px;

  .sigview-dialog-chart-clone-menu-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.react-grid-item > .react-resizable-handle {
  width: 50px !important;
  height: 50px !important;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid $secondaryColorLight !important;
  border-bottom: 2px solid $secondaryColorLight !important;
}

.react-grid-item.react-grid-placeholder {
  background: $secondaryColor !important;
  opacity: 0.2 !important;
}

.js-plotly-plot .plotly .main-svg {
  background-color: transparent !important;
}

.chart-placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .chart-placeholder-image {
    width: 200px;
    height: 200px;
    background-image: url("https://storage.googleapis.com/sigview-icons/illustrations/chartPlaceholder.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .chart-placeholder-title {
    display: inline-block;
    height: 24px !important;
    font-size: 12px;
    font-family: inherit;
    color: $secondaryColor !important;
    margin: 0px !important;
    // height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.datastory-preview-parent {
  padding: 10px;
  // padding-top: 0px !important;

  .datastory-preview-container {
    display: flex;
    width: 100%;
    margin-top: -10px;
    //border: 1px dotted gray;

    .datastory-preview {
      width: 100%;
    }

    &.left {
      flex-direction: row;
      .datastory-preview-metrics {
        width: 20%;
        //border-right: 1px dotted gray;
      }
      .datastory-preview-charts {
        width: 80%;
      }
    }

    &.right {
      flex-direction: row-reverse;
      .datastory-preview-metrics {
        width: 20%;
        //border-left: 1px dotted gray;
      }
      .datastory-preview-charts {
        width: 80%;
      }
    }

    &.top {
      flex-direction: column;
      .datastory-preview-metrics {
        // border-bottom: 1px dotted gray;
        width: 100%;
      }
      .datastory-preview-charts {
        width: 100%;
      }
    }

    &.bottom {
      flex-direction: column-reverse;
      .datastory-preview-metrics {
        //border-top: 1px dotted gray;
        width: 100%;
      }
      .datastory-preview-charts {
        width: 100%;
      }
    }

    .datastory-preview-box {
      background-color: $dsItemBgColor;
      box-shadow: $dsItemBoxShadow;
      border-radius: 3px;
      border: 1px dashed $secondaryColorLighter;

      &.published {
        border: 1px solid $secondaryColorLighter;
      }
    }
  }
}

// SIGIVEW - ICONS
.sigview-icon-editKpiSecondaryColorLight {
  background-image: url("https://storage.googleapis.com/sigview-icons/misc/iconEditKpiSecondaryColorLight.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  &:hover {
    background-image: url("https://storage.googleapis.com/sigview-icons/misc/iconEditKpiPrimaryColor.svg");
  }
}

.sigview-icon-editKpiPrimaryColor {
  background-image: url("https://storage.googleapis.com/sigview-icons/misc/iconEditKpiPrimaryColor.svg");
  width: 22px;
  height: 22px;
}

.sigview-icon-downloadXlsx {
  background-image: url("https://storage.googleapis.com/sigview-icons/misc/downloadXlsxSecondaryColor.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  // &:hover {
  //   background-image: url("https://storage.googleapis.com/sigview-icons/misc/iconEditKpiPrimaryColor.svg");
  // }
}

.sigview-icon-downloadCsv {
  background-image: url("https://storage.googleapis.com/sigview-icons/misc/downloadCsvSecondaryColor.svg");
  width: 19px;
  height: 19px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  // &:hover {
  //   background-image: url("https://storage.googleapis.com/sigview-icons/misc/iconEditKpiPrimaryColor.svg");
  // }
}

.ds-download-as-pdf-header {
  background-color: rgb(248, 249, 253);
  height: 40px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondaryColor;
  box-sizing: border-box;
  .ds-header-name {
    font-weight: 900;
  }
  .ds-header-report-icon {
    width: 116px;
    height: 22px;
    background-image: url("../assets/images/dsReportIconForPDF.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.ds-download-as-pdf-subheader {
  background-color: rgb(248, 249, 253);
  height: 10px;
  padding: 5px 20px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondaryColorLight;
  box-sizing: border-box;

  .ds-subheader-filter-description {
    font-size: 10px;
    font-weight: 100 !important;
  }
  .ds-download-as-pdf-date-range {
    font-size: 10px;
    color: $secondaryColorLight;
  }
}

// React-Grid-DND
.ws-react-dnd-container {
  display: flex;
  flex-direction: column;
  touch-action: none;
  width: 100%;
  height: 100%;
}

.ws-react-dnd-dropzone {
  flex: 1;
  height: 5000px;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 1rem;
}

.ws-react-dnd-grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 15px 15px 0px;
}

.ws-react-dnd-grid-item-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #08e;
  // display: flex;
  // justify-content: center;
  // color: white;
  // font-family: Arial, Helvetica, sans-serif;
  // align-items: center;
  // border-radius: 0.5em;
}

.menu-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 10px;
  padding: 0px calc(#{$masterPadding} + 5px); //Adding 5px to align with datastory below
  background-color: $mainContentBgColor;

  > * {
    flex: 1;
  }

  .menu-bar-left-panel {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    // width: calc(100% - 135px);
    height: 100%;
    // padding-right: 10px;

    .page-title {
      font-size: 15px;
      color: $secondaryColor;
      margin: 0px !important;
    }

    .menu-bar-basics {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
    }

    &.hidden {
      visibility: hidden;
    }

    .menu-bar-title {
      font-size: 16px;
      color: $secondaryColor;
      // width: 150px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // margin-right: 10px;
    }
  }

  .menu-bar-center-panel {
    padding: 0px 10px;
    #menuBarTimeFiltersContainer {
      width: max-content;
      box-shadow: $timeFiltersBoxShadow;
      // border-radius: 50px;
      border-radius: 5px;
      // padding: 0px 15px 0px 9px;
      padding: 0px 2px 0px 3px;
      background-color: $timeFiltersBgColor;
      // &.apply-visible {
      //   padding: 0px 5px 0px 3px;
      // }
    }
    #dsMenuBar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu-bar-right-panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 135px;
    height: 100%;

    .ds-menu-button-container {
      display: flex;
      align-items: center;
      // box-shadow: $dsItemBoxShadow;
    }
  }
}

.sigview-close-icon {
  border-radius: 50%;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  color: $secondaryColor;

  &:hover {
    background-color: $secondaryColorLightest;
  }
}
:root {
  --toastify-icon-color-success: #1eae98;
}

.Toastify__toast-body {
  font-family: "Fira Sans" !important;
  font-size: 14px;
}
div.css-1f8sh1y > div:nth-child(even) > div > div > div {
  height: 100%;
  width: 100%;
}
div.css-1f8sh1y > div:nth-child(odd) > div > div > div {
  height: 100%;
  width: 100%;
}
.ssoerror-totallogindata {
  width: 100%;
  /* height: 91vh; */
  float: left;
  /* background-image: url(/assets/images/loginbg.png);*/
  position: relative;
}
.ssoerror-redesign {
  width: 100%;
  height: calc(100vh - 28px);
  float: left;
  background-image: none !important;
  position: relative;
  background-color: #f9f9f9 !important;
}
.ssoerror-section {
  position: absolute;
  top: 45%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: auto;
  padding: 12.5%;
  min-height: 103px;
  width: 57%;
  max-width: 881px;
}
.sso-error-img {
  color: red;
  background: #f9f9f9;
  font-size: 50px;
}
.ssoerror-section-data {
  color: #385d6f;
  text-align: left;
  // padding: 25px 33%;
  line-height: 20px;
  list-style: disc !important;
  padding-top: 20px;
}
.ssoerror-section-data-heading {
  color: #385d6f;
  font-size: 30px;
  text-align: center;
  font-weight: 300;
  padding: 5px 0px 25px 0px;
}
.text {
  width: 96%;
  display: inline-block;
  color: #777;
}
.sso-error-img {
  color: #52baed !important;
  background: #f9f9f9;
  font-size: 50px;
}
