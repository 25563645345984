@import "../../../styles/sass/variables.scss";

@mixin filter-icon {
  border-radius: 50%;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  color: $secondaryColor;

  &:hover {
    background-color: $secondaryColorLightest;
  }
}

.global-filters-container-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $overlayBgColor;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 11000;
  
  &.no-backdrop{
    background-color: transparent !important;
  } 
  .global-filters-container {
    background-color: $popoverBgColor;
    // min-height: 513px;
    // min-width: 900px;
    width: 900px;
    position: relative;
    // padding-top: 10px;
    border-radius: 4px;

    .global-filters-container-backdrop {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: $overlayBgColor;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    .title-row-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px 4px 16px;
      cursor: move;
      border-bottom: 1px solid $secondaryColorLightest;

      .left-panel {
        display: flex;
        align-items: center;

        #globalFiltersDragIcon {
          font-size: 20px;
          cursor: move;
        }

        .global-filters-title {
          margin-right: 3px !important;
          color: $secondaryColor;
          font-weight: 600;
        }

        .global-filters-tour-icon {
          font-size: 14px;
          color: $secondaryColorLight;
          cursor: pointer;
          &:hover {
            color: $primaryColor;
          }
        }
      }

      .global-filter-close-icon {
        @include filter-icon;
      }
    }

    .global-time-filters-outer-container {
      height: max-content;
      // padding: 6px 16px 0px 16px;
      padding: 6px 16px 0px 10px; //left-align

      .global-time-filters-container {
        display: flex;
        // justify-content: center;
        justify-content: flex-start; //left-align
        align-items: center;
        padding: 0px;
      }
    }

    .dimension-filters-container {
      margin: 10px 16px 0px 16px;
      height: max-content;

      &.hidden {
        height: 0px !important;
        margin: 0px !important;
        border: none !important;
      }

      .dimension-dropdown-search-container {
        display: flex;
        width: 100%;
        padding: 0px;
        box-sizing: border-box;

        .dimension-dropdown-container {
          width: 160px;
          border-radius: 3px;
          // height: 30px;
          margin-right: 10px;
        }

        .search-dropdown-container {
          width: 50%;
          display: flex;
          // justify-content: center;
          align-items: center;
          border-radius: 3px;
          background-color: $secondaryColorLightest;
          border: 1px solid $secondaryColorLighter;

          .search-text-container {
            height: 100%;
            width: calc(100% - 52px);
            background-color: $popoverBgColor;
            border-radius: 3px 0px 0px 3px;
          }
          .search-icon-container {
            height: 100%;
            width: 26px;
            background-color: $secondaryColorLight;
            color: $secondaryColorLightest;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 0px 2px 2px 0px;

            &.clear-text-icon-container {
              background-color: $popoverBgColor !important;
              width: 26px;

              &.hidden {
                visibility: hidden;
              }

              .clear-text-icon {
                font-size: 14px !important;
                cursor: pointer;
                color: $secondaryColorLighter !important;
              }
            }

            &.disabled {
              pointer-events: none;
              color: $secondaryColorLighter;
              background-color: $secondaryColorLightest;
            }

            .search-icon {
              font-size: 20px;
              margin-left: 2px;
            }
          }
        }
      }

      .dimension-filters-options-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 7px 0px;
        align-items: center;

        .left-panel,
        .right-panel {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .dimension-select-all-container {
          width: 160px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // padding: 0px 10px;

          .dimension-select-all-title {
            cursor: pointer;
            color: $secondaryColor;
            font-size: 10px;
            font-weight: bold !important;
          }
        }

        .dimension-include-exclude-container {
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // width: 50%;
          // padding-left: 10px;

          #globalFiltersIncludeExclude {
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        .dimension-advanced-filters-container {
          // width: 25%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          // padding-right: 10px;
        }
      }

      .dimension-values-container {
        height: 260px;
        padding: 10px;
        overflow: auto;
        display: flex;
        min-width: 250px;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: content-box !important;
        // background-color: yellow;
        border: 1px solid $secondaryColorLightest;

        &.hidden {
          border: none !important;
        }

        .dimension-value-item-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          margin-right: 10px;
          min-width: 200px !important;
          cursor: pointer;

          .dimension-value-item-icon {
            color: $secondaryColorLighter;
            font-size: 16px !important;
            margin-right: 5px;
            // background-color: white !important;
          }

          .dimension-value-item-title {
            color: $secondaryColor;
            font-size: 11px;
            max-width: 200px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.selected {
            background-color: transparent !important;
            .dimension-value-item-icon {
              color: $primaryColor;
            }
            .dimension-value-item-title {
              color: $primaryColor;
            }
          }
        }
      }
    }

    .selected-dimension-filters-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 5px 16px;
      min-height: 31px;

      // .selected-dimensions-filters-gf-container {
      //   max-height: 62px;
      //   width: 100%;
      //   overflow: auto;
      // }
    }

    .selected-dimension-filters-title,
    .selected-dimension-filters-subtitle {
      margin: 0px 10px 0px 0px !important;
      font-size: 10px;
      font-family: inherit;
      margin: 3px 5px 3px 0px !important;
      height: 25px;
      // box-sizing: border-box;
      width: max-content !important;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .selected-dimension-filters-subtitle {
      color: $secondaryColorLight !important;
    }

    .global-filters-actions-bar-container {
      padding: 0px 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 45px;
    }

    .advanced-filters-container {
      box-sizing: border-box;
      z-index: 10000;
      border-radius: 3px 3px 0px 0px;

      .advanced-filters-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        border-bottom: none;
        // height: 30px;
        border-bottom: 1px solid $secondaryColorLightest;

        .advanced-filters-go-back-icon {
          font-size: 14px;
          // margin-right: 3px;
          // border-radius: 50%;
          cursor: pointer;
          color: $secondaryColor;

          &.part-2 {
            margin: 0px 0px 0px -9px;
          }
        }

        .advanced-filter-close-icon {
          @include filter-icon;
        }

        .advanced-filter-info-icon {
          font-size: 14px;
          margin-left: 5px;
          color: $secondaryColorLighter;
          cursor: pointer;
          &:hover {
            color: $secondaryColor;
          }
        }

        .left-panel {
          display: flex;
          align-items: center;

          .advanced-filters-title {
            font-size: 11px;
            margin: 0px !important;
            color: $secondaryColor;
            font-size: 14px;
          }
        }
      }

      .advanced-filters-row-container {
        height: 350px;
        overflow-y: auto;
        padding: 10px 15px;

        .advanced-filters-item-container {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          height: 40px !important;
          margin-bottom: 5px;
          // padding: 0px 10px;

          .advanced-filters-item-title-container {
            height: 100%;
            margin-right: 10px;

            .advanced-filters-item-title {
              margin: 0px !important;
              height: 24px !important;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 11px;
              color: $secondaryColor;
            }
          }

          .advanced-filters-item-date-container {
            height: 100%;
            margin-right: 5px;

            .advanced-filters-item-date {
              height: 24px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              color: $secondaryColor;
              border: 1px solid $secondaryColorLighter;
              border-radius: 3px;
              box-sizing: border-box;
              width: 170px;
              cursor: pointer;

              &.error {
                border: 1px solid $failureColor;
              }
            }

            .advanced-filters-item-date-error {
              height: 16px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 9px;
              box-sizing: border-box;
              color: $failureColor;
            }
          }

          .advanced-filters-item-icon-container {
            height: 100%;
            margin-right: 5px;

            &.hidden {
              display: none;
            }

            .advanced-filters-item-icon {
              height: 24px !important;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 16px !important;
              cursor: pointer;

              &.af-delete-icon {
                color: $secondaryColorLight;
                &:hover {
                  color: $failureColor;
                }
              }

              &.af-add-icon {
                color: $secondaryColor;
                background-color: $secondaryColorLightest;
                border-radius: 3px;
                width: 24px;
                height: 24px;
                justify-content: center;

                &:hover {
                  color: $secondaryColorLightest;
                  background-color: $secondaryColor;
                }
              }
            }
          }

          .advanced-filters-item-progress-bar-container {
            height: 100%;
            margin-right: 5px;

            .advanced-filters-item-progress-bar {
              height: 24px !important;
              width: 80px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 11px;
            }
          }

          .advanced-filters-item-type,
          .advanced-filters-item-value {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      .advanced-filters-actions-bar-container {
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        border-top: 1px solid $secondaryColorLightest;

        .left-panel,
        .right-panel {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.selected-filters-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  height: 100%;
  // cursor: pointer;

  .selected-filters-title {
    margin: 0px 5px 0px 0px !important;
    font-size: 10px;
    font-family: inherit;
    color: $secondaryColor;
  }

  .selected-filter-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    margin: 3px 5px 3px 0px;
    border-radius: 3px;
    height: 18px;
    box-sizing: border-box;
    cursor: pointer;

    &.no-padding {
      padding: 0px !important;
    }

    &#reportManagerInstance {
      padding: 0px !important;
      margin: 0px 5px 0px 0px !important;
    }

    &.header-container {
      padding: 0px;
    }

    &.include {
      background-color: $primaryColor;
    }

    &.exclude {
      background-color: $secondaryColor;
    }

    &.metric-item {
      border: 1px solid $secondaryColorLighter;
      .selected-filter-item-title {
        color: $secondaryColor;
        margin-left: 0px !important;
        margin-right: 3px !important;
      }
      .selected-filter-item-subtitle {
        font-size: 10px;
        margin-right: 3px;
        margin-left: 0px !important;
        color: $secondaryColor;

        &.header-container {
          margin: 0px !important;
        }
      }
    }

    .selected-filter-item-type-icon {
      font-size: 11px !important;
      color: $headerBgColor;
      margin-right: 3px !important;

      &.include {
        color: $headerBgColor;
      }
    }

        .selected-filter-item-title {
          font-size: 10.5px;
          white-space: nowrap;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 3px;
          // margin-left: 5px;
          color: $headerBgColor;
    
          &.header-container {
            margin: 0px !important;
            max-width: initial !important;
          }
        }

    .selected-filter-item-title1 {
      font-size: 10.5px;
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 3px;
      padding-left: 15px;
      // margin-left: 5px;
      color: $secondaryColor;

      &.header-container {
        margin: 0px !important;
        max-width: initial !important;
      }
    }

    .selected-filter-item-delete-icon {
      cursor: pointer;
      font-size: 12px;
      color: $headerBgColor;
      &:hover {
        color: $failureColor;
      }
    }
  }
}

.selected-filter-item-popover-container {
  font-size: 11px;
  font-family: inherit;
  padding: 10px 10px 7px 10px;
  background-color: $popoverBgColor;

  .selected-filter-item-popover-filter-type {
    border-bottom: 1px solid $secondaryColorLightest;
    color: $primaryColor;
    margin: 0px 0px 7px 0px;
    padding-bottom: 2px;
  }
  .selected-filter-item-popover-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3px;

    .selected-filter-item-popover-row-title {
      color: $secondaryColor;
      font-weight: bold;
      margin: 0px 3px 0px 0px;
      text-transform: uppercase;
    }

    .selected-filter-item-popover-row-value {
      color: $secondaryColor;
      margin: 0px;
    }

    .selected-filter-item-title {
      color: $secondaryColor;
    }

    .selected-filter-item-subtitle {
      margin-left: 3px !important;
      color: $secondaryColor;
    }
  }
}
