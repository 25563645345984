@import "../../../styles/sass/variables.scss";

.report-manager-container-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $overlayBgColor;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  padding: 50px;
  box-sizing: border-box;
}
