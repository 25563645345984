@import "../../../styles/sass/variables.scss";

.time-filters-overlay {
  border-radius: inherit;
  &.activated {
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: $overlayBgColor;
    width: 100vw;
    height: 100vh;
  }
}

.time-filters-outer-container {
  &.activated {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.time-filters-container {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  // background-color: $timeFiltersBgColor;
  height: 28px;
  padding: inherit;
  box-sizing: border-box;
  border-radius: inherit;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.activated {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50px;
    padding: 0px 15px 0px 9px;
  }

  .time-filters-comparison-text {
    .selected-date-formatted-icon {
      font-size: 24px !important;
    }
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDayToday .rdrDayNumber span {
  &:after {
    width: 0px !important;
    height: 0px !important;
  }
}

.date-time-range-picker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.date-time-range-picker-apply-cancel-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.date-time-range-picker-min-max {
  font-size: 12px;
  color: $primaryColor;
  // padding-left: 15px;
  font-style: italic;
}

.date-time-range-picker-apply-cancel-row {
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.time-filters-calendar-picker {
  // margin-right: 5px;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  color: $secondaryColor;
  font-size: 10px;
  width: max-content;
  padding: 0px 0px 0px 6px;
  background-color: transparent;
  color: $secondaryColor;
}

.rdrDateDisplayWrapper {
  background-color: $secondaryColorLightest;
}

// .sigview-react-button {
//   font-size: 12px;
// }

.sigview-date-range-picker {
  .rdrDayPassive .rdrDayNumber span {
    color: $secondaryColorLight;
  }

  .rdrDayNumber span {
    color: $secondaryColor;
  }

  .rdrDayDisabled {
    background-color: $buttonDisabledBgColor !important;
    // background-color: transparent !important;
    .rdrDayNumber span {
      color: $buttonDisabledColor !important;
      // color: $secondaryColorLighter !important;
      // color: transparent !important;
    }
  }

  .rdrDateDisplayItemActive {
    border-color: $primaryColor !important;
    border: 1px solid $primaryColor;
    input {
      color: $primaryColor;
      font-family: "Fira Sans";
    }
  }
  .rdrDateDisplayItem input {
    font-family: "Fira Sans";
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent $primaryColor;
    transform: translate(12px, 0px);
  }
  .rdrPprevButton i {
    border-color: transparent $primaryColor transparent transparent;
  }
  .rdrMonthAndYearPickers select:hover {
    background-color: $secondaryColorLightest;
  }
  .rdrMonthAndYearPickers select {
    padding: 5px 30px 5px 10px;
    font-size: 12px;
  }
  .rdrDateDisplayItem {
    box-shadow: none;
    border: 1px solid $secondaryColorLighter;
    background-color: $popoverBgColor;
  }
  .rdrStartEdge {
    color: $primaryColor !important;
    padding: 12px;
    top: 3px;
    left: 0px;
  }
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrEndEdge {
    color: $primaryColor !important;
    padding: 12px;
    top: 3px;
    right: 0px;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrInRange {
    color: $primaryColorLightest !important;
    padding: 12px;
    top: 3px;
    left: 0px;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $secondaryColorLight;
  }

  .rdrMonthAndYearWrapper {
    height: 40px;
  }
  .rdrDateDisplayItem input {
    height: 25px;
    font-size: 12px;
  }
  .rdrDay {
    height: 30px;
  }

  .rdrMonthPicker select {
    color: $secondaryColorLight;
  }

  .rdrYearPicker select {
    color: $secondaryColorLight;
  }

  // .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  //   .rdrDayHovered
  //   .rdrDayNumber:after {
  // }
  .rdrWeekDay {
    color: $secondaryColorLight;
  }
  .rdrMonth {
    padding: 0 10px 0px 10px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    color: $primaryColor !important;
  }
  .rdrCalendarWrapper {
    background-color: $popoverBgColor;
    .rdrMonthAndYearPickers {
      border: 1px solid $secondaryColorLightest !important;
      border-radius: 3px;
      height: 27px;
      margin-right: 150px;
      margin-left: 150px;
    }
    .rdrNextPrevButton {
      width: 45px;
      height: 25px;
      border-radius: 3px;
      background-color: transparent;
      border: 1px solid $primaryColor !important;
    }
  }
}

.sigview-date-single-select {
  &.rdrCalendarWrapper {
    background-color: $popoverBgColor;
    .rdrMonthAndYearPickers {
      // border: 1px solid $secondaryColorLightest !important;
      border-radius: 3px;
      height: 27px;
      // margin-right: 150px;
      // margin-left: 150px;
    }
    .rdrNextPrevButton {
      // width: 45px;
      height: 27px;
      border-radius: 3px;
      background-color: transparent;
      border: 1px solid $primaryColor !important;
    }
  }

  .rdrDayPassive .rdrDayNumber span {
    color: $secondaryColorLighter;
  }

  .rdrDayNumber span {
    color: $secondaryColor;
  }

  .rdrDayDisabled {
    background-color: $buttonDisabledBgColor !important;
    // background-color: transparent !important;
    .rdrDayNumber span {
      color: $buttonDisabledColor !important;
      // color: $secondaryColorLighter !important;
      // color: transparent !important;
    }
  }

  .rdrMonthPicker select {
    color: $secondaryColorLight;
  }

  .rdrYearPicker select {
    color: $secondaryColorLight;
  }

  .rdrSelected {
    color: $primaryColor !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $secondaryColorLightest;
  }

  .rdrWeekDay {
    color: $secondaryColorLight;
  }

  .rdrDayStartPreview.rdrDayEndPreview,
  .rdrDayHovered {
    color: $primaryColor !important;
  }

  .rdrNextButton {
    background-color: transparent;
    i {
      border-color: transparent transparent transparent $primaryColor;
    }
  }
  .rdrPprevButton {
    background-color: transparent;
    i {
      border-color: transparent $primaryColor transparent transparent;
    }
  }
}

.time-filters-comparison-calendar-container {
  width: max-content;
  padding: 8px 0px;

  .time-filters-comparison-calendar-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px 3px 9px;
    // margin-bottom: 7px;
    border-bottom: 1px solid $secondaryColorLighter;

    .time-filters-comparison-calendar-title {
      font-size: 12px;
      font-weight: 500;
      color: $secondaryColor;
      margin: 0px !important;
    }

    .time-filters-comparison-close-icon {
      font-size: 16px !important;
      cursor: pointer;
      border-radius: 50%;
      padding: 5px;
      color: $secondaryColor;

      &:hover {
        background-color: $secondaryColorLightest;
      }
    }
  }

  .time-filters-comparison-calendar-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 9px 7px 9px;
    border-bottom: 1px solid $secondaryColorLighter;
    height: 40px;
    box-sizing: border-box;

    .time-filters-comparison-date-picker {
      margin-right: 5px;
      height: 24px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $secondaryColorLighter;
      border-radius: 3px;
      box-sizing: border-box;
      cursor: pointer;
      color: $secondaryColor;
      font-size: 10px;
      // width: 160px;
      padding: 0px 10px;
      background-color: transparent;
    }

    .time-filters-comparison-row-menu {
      display: flex;
      align-items: center;

      .time-filters-comparison-row-delete-icon {
        height: 24px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px !important;
        cursor: pointer;
        color: gray;
        margin-left: 20px;
        &:hover {
          color: $failureColor;
        }
      }
    }
  }

  .time-filters-comparison-calendar-menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 9px 0px 9px;

    .left-panel {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .time-filters-comparison-calendar-compare-more-title {
        font-size: 10px;
        color: $secondaryColor;
        margin: 0px !important;
      }

      .time-filters-comparison-add-more-icon {
        font-size: 16px;
        color: $primaryColor;
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
}
