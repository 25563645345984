@import "../../../styles/sass/variables.scss";

.joyride-custom-tooltip-body {
  background-color: $popoverBgColor;
  font-family: inherit;
  border-radius: 4px;
  max-width: 250px;
  padding: 10px 0px 0px 0px;

  .joyride-custom-tooltip-title {
    padding: 0px 10px 5px 10px;
    color: $primaryColor;
    font-weight: 600;
    background-color: $popoverBgColor;
    margin: 0px !important;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
  }

  .joyride-custom-tooltip-content {
    padding: 5px 10px 10px 10px;
    font-size: 12px;
    line-height: 16px;
    color: $secondaryColor;
    margin: 0px !important;
  }

  .joyride-custom-tooltip-footer {
    padding: 0px 10px 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .joyride-custom-tooltip-left-panel,
    .joyride-custom-tooltip-right-panel {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
