@import "../../../styles/sass/variables.scss";

aside.sidenav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .sidenav-item {
    padding: 7px 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    .sidenav-item-title {
      font-size: 8.5px;
      color: $secondaryColor;
      padding: 6px 0px 0px 0px;
      text-align: center;
    }

    .custom-home-icon,
    .custom-filter-icon,
    .custom-report-icon,
    .custom-alert-icon,
    .custom-metricCharts-icon,
    .custom-dimensionTables-icon,
    .custom-share-icon,
    .custom-workspaces-icon {
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      background-position: center;
    }

    .custom-home-icon {
      background-image: url("../../../assets/images/home.svg");
    }

    .custom-filter-icon {
      background-image: url("../../../assets/images/filter.svg");
    }

    .custom-report-icon {
      background-image: url("../../../assets/images/report.svg");
    }

    .custom-alert-icon {
      background-image: url("../../../assets/images/alert.svg");
    }

    .custom-metricCharts-icon {
      background-image: url("../../../assets/images/metricCharts.svg");
    }

    .custom-dimensionTables-icon {
      background-image: url("../../../assets/images/dimensionTables.svg");
    }

    .custom-share-icon {
      background-image: url("../../../assets/images/share.svg");
    }

    .custom-workspaces-icon {
      background-image: url("../../../assets/images/workspaces.svg");
    }

    &:hover {
      background-color: $mainContentBgColor;

      .sidenav-item-title {
        color: $primaryColor;
      }
      .custom-home-icon {
        background-image: url("../../../assets/images/homeHovered.svg");
      }
      .custom-filter-icon {
        background-image: url("../../../assets/images/filterHovered.svg");
      }
      .custom-report-icon {
        background-image: url("../../../assets/images/reportHovered.svg");
      }
      .custom-alert-icon {
        background-image: url("../../../assets/images/alertHovered.svg");
      }
      .custom-metricCharts-icon {
        background-image: url("../../../assets/images/metricChartsHovered.svg");
      }
      .custom-dimensionTables-icon {
        background-image: url("../../../assets/images/dimensionTablesHovered.svg");
      }
      .custom-share-icon {
        background-image: url("../../../assets/images/shareHovered.svg");
      }
      .custom-workspaces-icon {
        background-image: url("../../../assets/images/workspacesHovered.svg");
      }
    }
  }

  &.dark {
    .sidenav-item {
      .custom-home-icon {
        background-image: url("../../../assets/images/home.svg");
      }

      .custom-filter-icon {
        background-image: url("../../../assets/images/filter.svg");
      }

      .custom-report-icon {
        background-image: url("../../../assets/images/report.svg");
      }

      .custom-alert-icon {
        background-image: url("../../../assets/images/alert.svg");
      }

      .custom-metricCharts-icon {
        background-image: url("../../../assets/images/metricCharts.svg");
      }

      .custom-dimensionTables-icon {
        background-image: url("../../../assets/images/dimensionTables.svg");
      }

      .custom-share-icon {
        background-image: url("../../../assets/images/share.svg");
      }

      .custom-workspaces-icon {
        background-image: url("../../../assets/images/workspaces.svg");
      }

      &:hover {
        background-color: $mainContentBgColor;

        .sidenav-item-title {
          color: $primaryColor;
        }
        .custom-home-icon {
          // background-image: url("../../../assets/images/homeHoveredDark.svg");
          background-image: url("../../../assets/images/homeHovered.svg");
        }
        .custom-filter-icon {
          // background-image: url("../../../assets/images/filterHoveredDark.svg");
          background-image: url("../../../assets/images/filterHovered.svg");
        }
        .custom-report-icon {
          // background-image: url("../../../assets/images/reportHoveredDark.svg");
          background-image: url("../../../assets/images/reportHovered.svg");
        }
        .custom-alert-icon {
          // background-image: url("../../../assets/images/alertHoveredDark.svg");
          background-image: url("../../../assets/images/alertHovered.svg");
        }
        .custom-metricCharts-icon {
          background-image: url("../../../assets/images/metricChartsHovered.svg");
        }
        .custom-dimensionTables-icon {
          background-image: url("../../../assets/images/dimensionTablesHovered.svg");
        }
        .custom-share-icon {
          background-image: url("../../../assets/images/shareHovered.svg");
        }
        .custom-workspaces-icon {
          background-image: url("../../../assets/images/workspacesHovered.svg");
        }
      }
    }
  }
}
