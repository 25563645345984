@import "../../../styles/sass/variables.scss";

.datastory-widget {
  width: 100%;
  height: 100%;
  // border: 1px dashed transparent;
  display: flex;
  flex-direction: column;

  .datastory-widget-header-container {
    flex-shrink: 0;
    height: max-content;
  }

  &:hover {
    .info-icon {
      visibility: visible !important;
    }
  }

  &.editable-on {
    cursor: grab;
    box-sizing: border-box;
    // border: 1px dashed $secondaryColorLighter;
    border-radius: 3px;
  }

  .datastory-widget-title-container {
    display: flex;
    align-items: center;

    &.custom-metric {
      padding: 10px 10px 0px 10px;
    }

    .datastory-widget-title {
      color: $secondaryColor;
      font-weight: 600 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: inherit;
      font-size: 14px;
      box-sizing: border-box;

      &.non-custom-metric {
        padding: 10px 10px 0px 10px;
      }
    }

    .info-icon {
      font-size: 13px !important;
      margin-left: 5px;
      visibility: hidden;
      color: $secondaryColorLighter;

      &:hover {
        color: $secondaryColor;
        cursor: pointer;
      }
    }
  }

  .widget {
    // height: calc(100% - 30px);
    padding: 0 10px;
    box-sizing: border-box;
    flex-basis: 0;
    flex-grow: 1;

    &.counter {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .datastory-counter-title {
        font-size: 40px;
        color: $secondaryColor;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: inherit;

        &.small {
          font-size: 20px;
        }

        &.medium {
          font-size: 30px;
        }

        &.large {
          font-size: 40px;
        }
      }
    }
  }
}
