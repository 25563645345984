@import "../../../styles/sass/variables.scss";

@mixin report-manager-icon {
  border-radius: 50%;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  color: $secondaryColor;

  &:hover {
    background-color: $secondaryColorLightest;
  }
}

.report-manager-container-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $overlayBgColor;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding: 50px;
  box-sizing: border-box;

  .report-manager-container {
    background-color: $popoverBgColor;
    width: 100%;
    position: relative;
    border-radius: 4px;
    height: 100%;

    .title-row-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px 4px 16px;
      height: 40px;
      box-sizing: border-box;
      border-bottom: 0px solid #f5f5f5;
      border-radius: 4px 4px 0px 0px;

      .left-panel {
        display: flex;
        align-items: center;
        padding-bottom: 0px;

        .title {
          margin-right: 3px !important;
          color: $secondaryColor;
        }
      }

      .close-icon {
        @include report-manager-icon;
      }
    }

    .content-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: calc(100% - 40px);

      .left-panel {
        width: 240px;
        height: 100%;
        border-radius: 0px 0px 0px 4px;
        background: $popoverBgColor;
        border-right: 0px solid #F5F5F5;
        box-sizing: border-box;

        .reports-sidenav {
          height: 100%;
          box-sizing: border-box;
          overflow: auto;
          // padding-top: 13px;

          &.error,
          &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          .report-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 3px;
            cursor: pointer;
            padding: 7px 16px;

            &.active,
            &:hover {
              background-color: $primaryColorLightest;
            }

            .report-row-left-panel {
              width: calc(100% - 40px);
              display: flex;
              // align-items: center;
              justify-content: flex-start;
              flex-direction: column;

              .report-row-title {
                font-size: 12px;
                margin-right: 7px !important;
                font-weight: 500;
                color: $secondaryColor;
                margin: 0px !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .report-row-subtitle {
                font-size: 9px;
                color: $secondaryColorLight;
                padding-top: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                    font-weight: 300;
              }
            }

            .report-row-right-panel {
              width: 40px;

              &.active {
                pointer-events: none;
                opacity: 0;
              }
            }

            &.download,
            &.saved {
              .report-row-left-panel {
                width: 100%;
              }
              .report-row-right-panel {
                display: none;
              }
            }
          }

          .no-data {
            font-size: 11px;
            color: $secondaryColor;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .top-panel {
          box-sizing: border-box;
          height: 59%;
          padding: 0px;
          border-top: 0px solid #f5f5f5;

          &.scheduled,
          &.download-unavailable {
            height: 100%;
            padding-bottom: 10px;
          }

          .add-report-button-container {
            display: flex;
            // justify-content: center;
            padding: 10px 16px 0px 16px;
            box-sizing: border-box;
            width: 100%;
            align-items: center;
            justify-content: center;
          }

          .reports-sidenav-container {
            box-sizing: border-box;
            height: calc(100% - 82px);
            border-bottom: 0px solid #F5F5F5;
          }
        }

        .bottom-panel {
          height: 41%;
          box-sizing: border-box;
          padding: 0px 0px 10px 0px;

          &.scheduled,
          &.download-unavailable {
            display: none;
          }

          .reports-sidenav-container {
            box-sizing: border-box;
            height: calc(100% - 48px);
          }
        }
        .report-type-nav-container {
          display: flex;
          // justify-content: flex-start;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          padding: 10px 0px 12px 0px;
          height: 28px;
          box-sizing: content-box;
        }

        .report-type-nav-container-history {
          display: flex;
          // justify-content: flex-start;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          padding: 10px 11px 12px 11px;
          height: 28px;
          box-sizing: content-box;
        }
      }

      .right-panel {
        width: calc(100% - 240px);
        height: 100%;
        border-radius: 0px 0px 4px 0px;
        //background: var(--mainContentBgColor);
        position: relative;
        border-top: 1px solid #edf1ff;
        border-top-left-radius: 25px;
        border-left: 1px solid #edf1ff;
        background: #f4f7ff;

        .loading-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 1;
          // border-right: 1px solid $secondaryColorLighter;
          // border-bottom: 1px solid $secondaryColorLighter;
          // box-sizing: border-box;
          background-color: $overlayBgColorLight;
        }

        // .right-panel-container {
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0px;
        //   left: 0px;
        // }

        &.no-report-selected-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #edf1ff;
          border-top-left-radius: 25px;
          border-left: 1px solid #edf1ff;

          .add-or-open-report-container {
            padding: 30px 50px;
            background-color: transparent;
            border-radius: 5px;
           // border: 1px solid #fff;
            
            //box-shadow: $dsItemBoxShadow;

            .no-report-selected-text {
              color: $secondaryColor;
              margin: 10px 0px 0px 0px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
            }
          }
        }

        .content-nav-bar {
          height: 28px;
          // box-sizing: border-box;
          // border-bottom: 1px solid $secondaryColorLighter;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          box-sizing: content-box !important;

          .tour-icon {
            font-size: 14px;
            color: $secondaryColorLight;
            cursor: pointer;
            height: 100%;
            align-items: center;
            display: flex;

            &:hover {
              color: $primaryColor;
            }
          }

          .content-nav-bar-container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 14px;
            padding: 0px !important;
            height: 28px !important;
            box-sizing: content-box !important;
          }
        }

        .content-main {
          height: calc(100% - 90px);
          box-sizing: border-box;
          overflow: auto !important;
          padding: 5px 22px;

          .report-manager-form-row {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 11px;
            align-items: center;

            &.full {
              width: 100%;
            }

            &.two-equal {
              > * {
                width: 50%;
              }
            }

            .report-manager-form-item-container {
              display: flex;
              //flex-direction: column;
              align-items: center;
              //justify-content: flex-start;
             // align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
                //background: #fff;
                //padding: 0px 15px;
                border-radius: 3px;
                min-height: 30px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
                background: transparent;
                //padding: 6px 15px;
                padding: 6px 0px;
                border-radius: 3px;
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: 100px;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }
              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }
              .filterBox {
                display: flex;
                align-items: center;
                border: 0px solid $secondaryColorLighter !important;
              }
              .add-Reportfilters-icon {
                margin-left: 5px;
              }
              .input-item-container-globalFilter {
                background-color: #fff;
                border: 1px solid #edf1ff;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }

                &.metrics-dimensions-selector-container {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: transparent !important;
                  //padding: 6px 12px;
                  border: 0px solid #fff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px 12px;
                    color: $primaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }      
              .input-item-container-RadioBtn {
                background-color: transparent;
                border: 0px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }

                &.metrics-dimensions-selector-container {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: #fff !important;
                  //padding: 6px 12px;
                  border: 0px solid #fff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
              .input-item-container {
                background-color: #fff;
                border: 1px solid #edf1ff;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }
                &.metrics-dimensions-selector-container{
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: #fff !important;
                  //padding: 6px 12px;
                  border: 1px solid #edf1ff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }
                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }

            .report-manager-form-item-containerBigBox {
              display: flex;
              flex-direction: column;
              //align-items: center;
              //justify-content: flex-start;
              // align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: max-content;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }

              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }

              .filterBox {
                display: flex;
                align-items: center;
                border: 1px solid $secondaryColorLighter !important;
              }

              .add-Reportfilters-icon {
                margin-left: 5px;
              }

              .input-item-container {
                // background-color: $secondaryColorLightest;
                border: 1px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  background-color: transparent !important;
                  border: 1px solid transparent;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 100%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }
          }
          .report-manager-form-row-preview {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;

            &.full {
              width: 100%;
            }

            &.two-equal {
              >* {
                width: 50%;
              }
            }

            .report-manager-form-item-container {
              display: flex;
              flex-direction: column;
              //align-items: center;
              justify-content: flex-start;
              align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: max-content;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }

              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }

              .filterBox {
                display: flex;
                align-items: center;
                border: 1px solid $secondaryColorLighter !important;
              }

              .add-Reportfilters-icon {
                margin-left: 5px;
              }

              .input-item-container {
                // background-color: $secondaryColorLightest;
                //border: 1px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;
                border: 1px solid #edf1ff;
                background: #fff;

                &.full {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  background-color: #fff !important;
                  border: none !important;
                }

                &.label-less {
                  margin-top: 16px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 100%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: #f0f2f5;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }
          }

          .report-manager-preview-container {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            width: 100%;
            height: 100%;

            .centered {
              justify-content: center;
              align-items: center;
            }

            .sigview-table-container.report-manager-preview {
              border: 1px solid #edf1ff;
              // border-radius: 4px;
              // margin-bottom: 5px;
              // margin-top: 5px;
              overflow: auto;

              .sigview-table {
                font-size: 11px;
                width: 100%;
                border-collapse: separate !important;
                border-spacing: 0px !important;

                .sigview-table-head-cell {
                  background: #f0f2f5 !important;
                  text-align: center;
                  color: $secondaryColor;
                  padding: 8px 10px;
                  text-transform: uppercase;
                  font-weight: 500;
                  // width: 200px;
                  // max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.5 !important;
                }

                .sigview-table-row {
                  padding: 5px 7px;
                  padding: 0px !important;
                  text-align: start;
                  // width: 200px !important;
                  // min-width: 200px !important;
                  // max-width: 200px !important;
                  word-wrap: break-word;
                  line-height: 16px;
                  color: $secondaryColor;

                  .sigview-table-cell {
                    text-transform: uppercase;
                    padding: 3px 7px;

                    &.dimension {
                      text-align: start;

                      span.sigview-table-cell-value {
                        width: max-content;
                        max-width: 200px;
                        display: inline-block;
                      }
                    }

                    &.metric,
                    &.customMetric {
                      text-align: center;
                      width: max-content;

                      span.sigview-table-cell-value {
                        width: max-content;
                        display: inline-block;
                      }
                    }

                    &.neg-change {
                      color: $negChangeColor;
                      // background-color: $popoverBgColor;
                    }

                    &.pos-change {
                      color: $posChangeColor;
                      // background-color: $popoverBgColor;
                    }
                  }

                  .cell-change-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    span.cell-change-value {
                      width: max-content;
                      display: inline-block;
                    }

                    .cell-change-icon {
                      font-size: 20px !important;
                      display: inline-block;
                    }

                    .cell-change-icon-placeholder {
                      width: 20px !important;
                      display: inline-block;
                    }
                  }

                  .padded {
                    padding: 10px;
                  }
                }

                tr.sigview-table-row:nth-child(even) {
                  background: #f9f9fa;
                }
              }
            }

            .feature-wip-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .feature-wip-container-image {
                background-image: url("../../../assets/images/underProgress.svg");
                background-position: center; /* Center the image */
                background-size: contain; /* Resize the background image to cover the entire container */
                background-repeat: no-repeat;
                width: 200px;
                height: 200px;
              }

              .feature-wip-container-title {
                color: $secondaryColor;
                font-size: 11px;
              }
            }
          }
        }

        .content-menu-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0px 5px;

          .left-panel,
          .right-panel {
            height: 40px;
            box-sizing: border-box;
            background: $popoverBgColor;
            border-top: 1px solid  #e7ecff;
            border-radius: 0px 0px 4px 0px;
            display: flex;
            align-items: center;
            border-left: none;
            background-color: #f4f7ff;
          }

          .left-panel {
            justify-content: flex-start;
            padding-left: 5px;
            border-right: none !important;
          }
          .right-panel {
            justify-content: flex-end;
            padding-right: 5px;
          }
        }
      }
      .right-panel-admin-report {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 4px 0px;
        //background: var(--mainContentBgColor);
        position: relative;
        border-top: 1px solid #edf1ff;
        //border-top-left-radius: 25px;
        border-left: 1px solid #edf1ff;
        background: #f4f7ff;
        margin-top:10px;

        .loading-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 1;
          // border-right: 1px solid $secondaryColorLighter;
          // border-bottom: 1px solid $secondaryColorLighter;
          // box-sizing: border-box;
          background-color: $overlayBgColorLight;
        }

        // .right-panel-container {
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0px;
        //   left: 0px;
        // }

        &.no-report-selected-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #edf1ff;
          border-top-left-radius: 25px;
          border-left: 1px solid #edf1ff;

          .add-or-open-report-container {
            padding: 30px 50px;
            background-color: transparent;
            border-radius: 5px;
            // border: 1px solid #fff;

            //box-shadow: $dsItemBoxShadow;

            .no-report-selected-text {
              color: $secondaryColor;
              margin: 10px 0px 0px 0px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
            }
          }
        }

        .content-nav-bar {
          height: 28px;
          // box-sizing: border-box;
          // border-bottom: 1px solid $secondaryColorLighter;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          box-sizing: content-box !important;

          .tour-icon {
            font-size: 14px;
            color: $secondaryColorLight;
            cursor: pointer;
            height: 100%;
            align-items: center;
            display: flex;

            &:hover {
              color: $primaryColor;
            }
          }

          .content-nav-bar-container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 14px;
            padding: 0px !important;
            height: 28px !important;
            box-sizing: content-box !important;
          }
        }

        .content-main {
          height: calc(100% - 90px);
          box-sizing: border-box;
          overflow: auto !important;
          padding: 5px 22px;

          .report-manager-form-row {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 11px;
            align-items: center;

            &.full {
              width: 100%;
            }

            &.two-equal {
              >* {
                width: 50%;
              }
            }

            .report-manager-form-item-container {
              display: flex;
              //flex-direction: column;
              align-items: center;
              //justify-content: flex-start;
              // align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
                //background: #fff;
                //padding: 0px 15px;
                border-radius: 3px;
                min-height: 30px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
                background: transparent;
                //padding: 6px 15px;
                padding: 6px 0px;
                border-radius: 3px;
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: 100px;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }

              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }

              .filterBox {
                display: flex;
                align-items: center;
                border: 0px solid $secondaryColorLighter !important;
              }

              .add-Reportfilters-icon {
                margin-left: 5px;
              }

              .input-item-container-globalFilter {
                background-color: #fff;
                border: 1px solid #edf1ff;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }

                &.metrics-dimensions-selector-container {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: transparent !important;
                  //padding: 6px 12px;
                  border: 0px solid #fff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px 12px;
                    color: $primaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }

              .input-item-container-RadioBtn {
                background-color: transparent;
                border: 0px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }

                &.metrics-dimensions-selector-container {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: #fff !important;
                  //padding: 6px 12px;
                  border: 0px solid #fff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }

              .input-item-container {
                background-color: #fff;
                border: 1px solid #edf1ff;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 90%;
                }

                &.metrics-dimensions-selector-container {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  //background-color: #fff !important;
                  //padding: 6px 12px;
                  border: 1px solid #edf1ff;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  //width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 90%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }

            .report-manager-form-item-containerBigBox {
              display: flex;
              flex-direction: column;
              //align-items: center;
              //justify-content: flex-start;
              // align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: max-content;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }

              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }

              .filterBox {
                display: flex;
                align-items: center;
                border: 1px solid $secondaryColorLighter !important;
              }

              .add-Reportfilters-icon {
                margin-left: 5px;
              }

              .input-item-container {
                // background-color: $secondaryColorLightest;
                border: 1px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;

                &.full {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  background-color: transparent !important;
                  border: 1px solid transparent;
                }

                &.label-less {
                  margin-top: 0px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 100%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: $secondaryColorLighter;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }
          }

          .report-manager-form-row-preview {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;

            &.full {
              width: 100%;
            }

            &.two-equal {
              >* {
                width: 50%;
              }
            }

            .report-manager-form-item-container {
              display: flex;
              flex-direction: column;
              //align-items: center;
              justify-content: flex-start;
              align-items: flex-start;
              min-height: 35px !important;
              // margin-bottom: 5px;
              // padding: 0px 0px 0px 12px;
              margin-right: 20px;
              // width: 100%;
              box-sizing: border-box;

              &:last-child {
                margin-right: 0px !important;
              }

              &.full {
                width: 100%;
              }

              &.clone-visible {
                width: calc(100% - 210px);
              }

              &.label-less {
                margin-left: -15px !important;

                &.margin-less {
                  min-height: max-content !important;
                  margin-left: 0px !important;
                }
              }

              .label-container {
                height: 16px;
                // margin-bottom: 5px;
                // margin-right: 5px;
                width: max-content;

                .label {
                  // height: 24px !important;
                  margin-bottom: 5px !important;
                  padding: 0px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  color: $secondaryColor;
                  font-weight: 400;
                }
              }

              .selectedFilter {
                width: 35px;
                margin-top: 3px;
                margin-bottom: 3px;
              }

              .filterBox {
                display: flex;
                align-items: center;
                border: 1px solid $secondaryColorLighter !important;
              }

              .add-Reportfilters-icon {
                margin-left: 5px;
              }

              .input-item-container {
                // background-color: $secondaryColorLightest;
                //border: 1px solid $secondaryColorLighter;
                border-radius: 3px;
                box-sizing: border-box;
                border: 1px solid #edf1ff;
                background: #fff;

                &.full {
                  width: 100%;
                }

                &.border-less {
                  border: none !important;
                }

                &.transparent {
                  background-color: #fff !important;
                  border: none !important;
                }

                &.label-less {
                  margin-top: 16px;

                  &.margin-less {
                    margin-top: 0px !important;
                  }
                }

                &.report-clone-menu-container {
                  box-sizing: border-box;
                  width: 210px;
                  display: flex;
                  justify-content: flex-end;
                }

                &.clone-visible {
                  width: 100%;
                }

                &.padded {
                  padding: 5px;
                  margin: 5px 0px;
                }

                .info-title {
                  font-size: 12px;
                  font-family: inherit;
                  color: $secondaryColorLight !important;
                  margin: 0px !important;
                  // height: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  &.add-filters-text {
                    cursor: pointer;
                    padding: 7px;
                    color: $secondaryColor !important;
                  }
                }

                .report-manager-form-ad-hoc-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;
                }

                .error-message {
                  font-size: 10px;
                  padding: 5px 0px;
                  color: $failureColor;
                }

                .metrics-dimensions-selector-container {
                  width: 100%;
                }

                .report-manager-preview-sql-query-container {
                  width: 100%;
                  max-height: 150px;
                  max-width: 100%;
                  box-sizing: border-box;
                  background-color: transparent !important;
                  border: 1px solid transparent !important;
                  resize: none;
                  overflow: hidden;
                  // transition: all ease-in 0.3s;

                  &.expanded {
                    height: max-content;
                    max-height: max-content;
                  }

                  .report-manager-sql-query {
                    font-size: 12px !important;
                    color: $secondaryColor;
                    white-space: pre-wrap;

                    .quantity-dimension,
                    .quantity-metric {
                      background-color: #f0f2f5;
                      padding: 3px;
                      display: inline-block;
                      border-radius: 3px;
                      margin-bottom: 2px;
                    }

                    .dimensions-list,
                    .metrics-list,
                    // .filters-pre,
                    // .groupby,
                    // .orderby,
                    .dimensions-filters,
                    .metric-filters,
                    .time-filters,
                    .time-filters-compare {
                      display: inline-block;
                      padding-left: 20px;
                      line-height: 15px;
                      // margin-bottom: 10px;
                      // border-bottom: 3px solid red;
                    }

                    // .ad-filters {
                    //   padding-left: 40px;
                    //   display: inline-block;
                    // }

                    .query-keyword {
                      color: $primaryColor;
                    }
                  }
                }
              }
            }
          }

          .report-manager-preview-container {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            width: 100%;
            height: 100%;

            .centered {
              justify-content: center;
              align-items: center;
            }

            .sigview-table-container.report-manager-preview {
              border: 1px solid #edf1ff;
              // border-radius: 4px;
              // margin-bottom: 5px;
              // margin-top: 5px;
              overflow: auto;

              .sigview-table {
                font-size: 11px;
                width: 100%;
                border-collapse: separate !important;
                border-spacing: 0px !important;

                .sigview-table-head-cell {
                  background: #f0f2f5 !important;
                  text-align: center;
                  color: $secondaryColor;
                  padding: 8px 10px;
                  text-transform: uppercase;
                  font-weight: 500;
                  // width: 200px;
                  // max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.5 !important;
                }

                .sigview-table-row {
                  padding: 5px 7px;
                  padding: 0px !important;
                  text-align: start;
                  // width: 200px !important;
                  // min-width: 200px !important;
                  // max-width: 200px !important;
                  word-wrap: break-word;
                  line-height: 16px;
                  color: $secondaryColor;

                  .sigview-table-cell {
                    text-transform: uppercase;
                    padding: 3px 7px;

                    &.dimension {
                      text-align: start;

                      span.sigview-table-cell-value {
                        width: max-content;
                        max-width: 200px;
                        display: inline-block;
                      }
                    }

                    &.metric,
                    &.customMetric {
                      text-align: center;
                      width: max-content;

                      span.sigview-table-cell-value {
                        width: max-content;
                        display: inline-block;
                      }
                    }

                    &.neg-change {
                      color: $negChangeColor;
                      // background-color: $popoverBgColor;
                    }

                    &.pos-change {
                      color: $posChangeColor;
                      // background-color: $popoverBgColor;
                    }
                  }

                  .cell-change-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    span.cell-change-value {
                      width: max-content;
                      display: inline-block;
                    }

                    .cell-change-icon {
                      font-size: 20px !important;
                      display: inline-block;
                    }

                    .cell-change-icon-placeholder {
                      width: 20px !important;
                      display: inline-block;
                    }
                  }

                  .padded {
                    padding: 10px;
                  }
                }

                tr.sigview-table-row:nth-child(even) {
                  background: #f9f9fa;
                }
              }
            }

            .feature-wip-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .feature-wip-container-image {
                background-image: url("../../../assets/images/underProgress.svg");
                background-position: center;
                /* Center the image */
                background-size: contain;
                /* Resize the background image to cover the entire container */
                background-repeat: no-repeat;
                width: 200px;
                height: 200px;
              }

              .feature-wip-container-title {
                color: $secondaryColor;
                font-size: 11px;
              }
            }
          }
        }

        .content-menu-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0px 5px;

          .left-panel,
          .right-panel {
            height: 40px;
            box-sizing: border-box;
            background: $popoverBgColor;
            border-top: 1px solid #e7ecff;
            border-radius: 0px 0px 4px 0px;
            display: flex;
            align-items: center;
            border-left: none;
            background-color: #f4f7ff;
          }

          .left-panel {
            justify-content: flex-start;
            padding-left: 5px;
            border-right: none !important;
          }

          .right-panel {
            justify-content: flex-end;
            padding-right: 5px;
          }
        }
      }
      // .right-panel-admin-report123 {
      //   width: 100%;
      //   height: 100%;
      //   border-radius: 0px 0px 4px 0px;
      //   //background: $popoverBgColor;
      //   position: relative;
      //   border-top: 1px solid #edf1ff;
      //   border-left: 1px solid #edf1ff;
      //   background: #f4f7ff;
      //   margin-top: 10px;

      //   .loading-overlay {
      //     width: 100%;
      //     height: 100%;
      //     position: absolute;
      //     top: 0px;
      //     left: 0px;
      //     z-index: 1;
      //     // border-right: 1px solid $secondaryColorLighter;
      //     // border-bottom: 1px solid $secondaryColorLighter;
      //     // box-sizing: border-box;
      //     background-color: $overlayBgColorLight;
      //   }

      //   // .right-panel-container {
      //   //   width: 100%;
      //   //   height: 100%;
      //   //   position: absolute;
      //   //   top: 0px;
      //   //   left: 0px;
      //   // }

      //   &.no-report-selected-container {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     align-items: center;

      //     .add-or-open-report-container {
      //       padding: 30px 50px;
      //       background-color: $secondaryColorLightest;
      //       border-radius: 5px;
      //       border: 1px dashed $secondaryColorLight;
      //       box-shadow: $dsItemBoxShadow;

      //       .no-report-selected-text {
      //         color: $secondaryColor;
      //         margin: 10px 0px 0px 0px !important;
      //         display: flex;
      //         justify-content: center;
      //         align-items: center;
      //         font-size: 12px;
      //       }
      //     }
      //   }

      //   .content-nav-bar {
      //     height: 28px;
      //     // box-sizing: border-box;
      //     // border-bottom: 1px solid $secondaryColorLighter;
      //     display: flex;
      //     justify-content: flex-start;
      //     align-items: center;
      //     padding: 10px;
      //     box-sizing: content-box !important;

      //     .tour-icon {
      //       font-size: 14px;
      //       color: $secondaryColorLight;
      //       cursor: pointer;
      //       height: 100%;
      //       align-items: center;
      //       display: flex;

      //       &:hover {
      //         color: $primaryColor;
      //       }
      //     }

      //     .content-nav-bar-container {
      //       display: flex;
      //       justify-content: center;
      //       align-items: flex-end;
      //       font-size: 14px;
      //       padding: 0px !important;
      //       height: 28px !important;
      //       box-sizing: content-box !important;
      //     }
      //   }

      //   .content-main {
      //     height: calc(100% - 90px);
      //     box-sizing: border-box;
      //     overflow: auto !important;
      //     padding: 3px 22px;

      //     .report-manager-form-row {
      //       display: flex;
      //       justify-content: flex-start;
      //       margin-bottom: 14px;

      //       &.full {
      //         width: 100%;
      //       }

      //       &.two-equal {
      //         > * {
      //           width: 50%;
      //         }
      //       }

      //       .report-manager-form-item-container {
      //         display: flex;
      //         //flex-direction: column;
      //         justify-content: flex-start;
      //         align-items: flex-start;
      //         min-height: 40px !important;
      //         // margin-bottom: 5px;
      //         // padding: 0px 0px 0px 12px;
      //         margin-right: 20px;
      //         // width: 100%;
      //         box-sizing: border-box;
      //         align-items: center;

      //         &:last-child {
      //           margin-right: 0px !important;
      //         }

      //         &.full {
      //           width: 100%;
      //         }

      //         &.clone-visible {
      //           width: calc(100% - 250px);
      //         }

      //         &.label-less {
      //           margin-left: -15px !important;

      //           &.margin-less {
      //             min-height: max-content !important;
      //             margin-left: 0px !important;
      //           }
      //         }

      //         .label-container {
      //           height: 16px;
      //           // margin-bottom: 5px;
      //           // margin-right: 5px;
      //           width: 100px;

      //           .label {
      //             // height: 24px !important;
      //             margin-bottom: 5px !important;
      //             padding: 0px !important;
      //             display: flex;
      //             justify-content: flex-start;
      //             align-items: center;
      //             font-size: 11px;
      //             color: $secondaryColor;
      //           }
      //         }

      //         .input-item-container {
      //           // background-color: $secondaryColorLightest;
      //           background-color: #fff;
      //           border: 1px solid #edf1ff;
      //           border-radius: 3px;
      //           box-sizing: border-box;

      //           &.full {
      //             width: 100%;
      //           }

      //           &.border-less {
      //             border: none !important;
      //           }

      //           &.transparent {
      //             background-color: transparent !important;
      //             border: 1px solid transparent;
      //           }

      //           &.label-less {
      //             margin-top: 16px;

      //             &.margin-less {
      //               margin-top: 0px !important;
      //             }
      //           }

      //           &.report-clone-menu-container {
      //             box-sizing: border-box;
      //             width: 250px;
      //             display: flex;
      //             justify-content: flex-end;
      //           }

      //           &.clone-visible {
      //             width: 100%;
      //           }

      //           &.padded {
      //             padding: 5px;
      //           }

      //           .info-title {
      //             font-size: 10px;
      //             font-family: inherit;
      //             color: $secondaryColorLight !important;
      //             margin: 0px !important;
      //             // height: 24px;
      //             display: flex;
      //             justify-content: flex-start;
      //             align-items: center;

      //             &.add-filters-text {
      //               cursor: pointer;
      //               padding: 7px;
      //               color: $secondaryColor !important;
      //             }
      //           }

      //           .report-manager-form-ad-hoc-container {
      //             display: flex;
      //             justify-content: flex-end;
      //             align-items: center;
      //             width: 100%;
      //           }

      //           .error-message {
      //             font-size: 10px;
      //             padding: 5px 0px;
      //             color: $failureColor;
      //           }

      //           .metrics-dimensions-selector-container {
      //             width: 100%;
      //           }

      //           .report-manager-preview-sql-query-container {
      //             width: 100%;
      //             max-height: 150px;
      //             max-width: 100%;
      //             box-sizing: border-box;
      //             background-color: transparent !important;
      //             border: 1px solid transparent !important;
      //             resize: none;
      //             overflow: hidden;
      //             // transition: all ease-in 0.3s;

      //             &.expanded {
      //               height: max-content;
      //               max-height: max-content;
      //             }

      //             .report-manager-sql-query {
      //               font-size: 12px !important;
      //               color: $secondaryColor;
      //               white-space: pre-wrap;

      //               .quantity-dimension,
      //               .quantity-metric {
      //                 background-color: $secondaryColorLighter;
      //                 padding: 3px;
      //                 display: inline-block;
      //                 border-radius: 3px;
      //                 margin-bottom: 2px;
      //               }

      //               .dimensions-list,
      //               .metrics-list,
      //               // .filters-pre,
      //               // .groupby,
      //               // .orderby,
      //               .dimensions-filters,
      //               .metric-filters,
      //               .time-filters,
      //               .time-filters-compare {
      //                 display: inline-block;
      //                 padding-left: 20px;
      //                 line-height: 15px;
      //                 // margin-bottom: 10px;
      //                 // border-bottom: 3px solid red;
      //               }
      //               // .ad-filters {
      //               //   padding-left: 40px;
      //               //   display: inline-block;
      //               // }

      //               .query-keyword {
      //                 color: $primaryColor;
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }

      //     .report-manager-preview-container {
      //       display: flex;
      //       flex-direction: column;
      //       // justify-content: center;
      //       // align-items: center;
      //       width: 100%;
      //       height: 100%;

      //       .centered {
      //         justify-content: center;
      //         align-items: center;
      //       }

      //       .sigview-table-container.report-manager-preview {
      //         border: 1px solid #edf1ff;
      //         // border-radius: 4px;
      //         // margin-bottom: 5px;
      //         // margin-top: 5px;
      //         overflow: auto;

      //         .sigview-table {
      //           font-size: 11px;
      //           width: 100%;
      //           border-collapse: separate !important;
      //           border-spacing: 2px !important;

      //           .sigview-table-head-cell {
      //             background: $secondaryColorLighter !important;
      //             text-align: center;
      //             color: $secondaryColor;
      //             padding: 8px 10px;
      //             text-transform: capitalize;
      //             font-weight: 600;
      //             // width: 200px;
      //             // max-width: 200px;
      //             overflow: hidden;
      //             text-overflow: ellipsis;
      //             line-height: 1.5 !important;
      //           }

      //           .sigview-table-row {
      //             padding: 5px 7px;
      //             padding: 0px !important;
      //             text-align: start;
      //             // width: 200px !important;
      //             // min-width: 200px !important;
      //             // max-width: 200px !important;
      //             word-wrap: break-word;
      //             line-height: 16px;
      //             color: $secondaryColor;

      //             .sigview-table-cell {
      //               text-transform: uppercase;
      //               padding: 3px 7px;

      //               &.dimension {
      //                 text-align: start;

      //                 span.sigview-table-cell-value {
      //                   width: max-content;
      //                   max-width: 200px;
      //                   display: inline-block;
      //                 }
      //               }

      //               &.metric,
      //               &.customMetric {
      //                 text-align: center;
      //                 width: max-content;

      //                 span.sigview-table-cell-value {
      //                   width: max-content;
      //                   display: inline-block;
      //                 }
      //               }

      //               &.neg-change {
      //                 color: $negChangeColor;
      //                 // background-color: $popoverBgColor;
      //               }

      //               &.pos-change {
      //                 color: $posChangeColor;
      //                 // background-color: $popoverBgColor;
      //               }
      //             }

      //             .cell-change-container {
      //               display: flex;
      //               justify-content: space-between;
      //               align-items: center;
      //               width: 100%;

      //               span.cell-change-value {
      //                 width: max-content;
      //                 display: inline-block;
      //               }

      //               .cell-change-icon {
      //                 font-size: 20px !important;
      //                 display: inline-block;
      //               }

      //               .cell-change-icon-placeholder {
      //                 width: 20px !important;
      //                 display: inline-block;
      //               }
      //             }

      //             .padded {
      //               padding: 10px;
      //             }
      //           }

      //           tr.sigview-table-row:nth-child(even) {
      //             background: $secondaryColorLightest;
      //           }
      //         }
      //       }

      //       .feature-wip-container {
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: center;
      //         align-items: center;

      //         .feature-wip-container-image {
      //           background-image: url("../../../assets/images/underProgress.svg");
      //           background-position: center; /* Center the image */
      //           background-size: contain; /* Resize the background image to cover the entire container */
      //           background-repeat: no-repeat;
      //           width: 200px;
      //           height: 200px;
      //         }

      //         .feature-wip-container-title {
      //           color: $secondaryColor;
      //           font-size: 11px;
      //         }
      //       }
      //     }
      //   }

      //   .content-menu-bar {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     // padding: 0px 5px;

      //     .left-panel,
      //     .right-panel {
      //       height: 40px;
      //       box-sizing: border-box;
      //       background: $popoverBgColor;
      //       border-top: 1px solid $secondaryColorLighter;
      //       border-radius: 0px 0px 4px 0px;
      //       display: flex;
      //       align-items: center;
      //     }

      //     .left-panel {
      //       justify-content: flex-start;
      //       padding-left: 5px;
      //       border-right: none !important;
      //     }

      //     .right-panel {
      //       justify-content: flex-end;
      //       padding-right: 5px;
      //     }
      //   }
      // }
    }
  }
}
