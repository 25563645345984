@import "../../../../styles/sass/variables.scss";

/*table chart css*/

.sigview-table-container {
  border: 1px solid $secondaryColorLightest;
  border-radius: 4px;
  // margin-bottom: 5px;
  // margin-top: 5px;
  overflow: auto;
  width: 100% !important;
  // height: 100%; // Will look bad if there are less rows

  .sigview-table {
    font-size: 11px;
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 2px !important;
    // height: 100%; // Will look bad if there are less rows

    .sigview-table-head-cell {
      background: $primaryColor;
      text-align: center;
      color: $buttonColor;
      padding: 8px 10px;
      text-transform: capitalize;
      font-weight: 600;
      // width: 200px;
      // max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5 !important;
    }

    .sigview-table-row {
      padding: 5px 7px;
      padding: 0px !important;
      text-align: start;
      word-wrap: break-word;
      line-height: 16px;
      color: $secondaryColor;

      .sigview-table-cell {
        text-transform: uppercase;
        padding: 3px 7px;

        &.dimension {
          text-align: start;

          span.sigview-table-cell-value {
            width: max-content;
            max-width: 200px;
            display: inline-block;
          }
        }

        &.metric,
        &.customMetric {
          text-align: center;
          width: max-content;

          span.sigview-table-cell-value {
            width: max-content;
            display: inline-block;
          }
        }
      }

      .padded {
        padding: 10px;
      }
    }

    tr.sigview-table-row:nth-child(even) {
      background: $secondaryColorLightest;
    }
  }
}
