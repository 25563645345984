:root body.light {
  //PRIMARY
  --primaryColor: #267ee6;
  --primaryColorLight: #6ea9ee; //-30
  --primaryColorLighter: #b6d4f7; //-60
  --primaryColorLightest: #eaf3ff; //-80

  //SECONDARY
  --secondaryColor: #46596a; //REFER: https://docusaurus.io/docs/styling-layout
  // --secondaryColorLight: #758ea4; //-60
  // --secondaryColorLighter: #aabac7; //-110
  // --secondaryColorLightest: #d5dce3; //-150
  --secondaryColorLight:#607282; //-70
  --secondaryColorLighter: #c5cfd9; //-135
  --secondaryColorLightest: #eff2f5; //-175
  --tableRowBGColor:#f8f9fc;

  //HEADER
  --headerBgColor: #ffffff;
  --headerBoxShadow: 0px 4px 4px 0px #e9e9e940;

  //USER INFO
  --userInfoBgColor: #ffffff;

  //SIDENAV
  --sidenavBgColor: #ffffff;
  --sidenavItemColor: #607282;
  --sidenavItemHoverColor: #f8f9fd;

  //MAIN CONTENT
 // --mainContentBgColor: #f8f9fd; older --mainContentBgColor:
    --mainContentBgColor: #f4f7ff;

  //DATASTORY
  --dsItemBgColor: #ffffff;
  --dsItemBoxShadow: 0px 0px 7px 0px #d7d9e2;

  //TIME FILTERS
  --timeFiltersBgColor: #ffffff;
  --timeFiltersBoxShadow: 0px 0px 7px 0px #eff2ff;

  //BUTTONS
  --buttonBgColor: #46596a;
  --buttonBgColorLight: #607282;
  --buttonBgColorLighter: #c5cfd9;
  --buttonBgColorLightest: #eff2f5;
  --buttonColor: #ffffff;
  --buttonColorLight: #46596a;
  --buttonColorLighter: #46596a;
  --buttonColorLightest: #46596a;
  --buttonColorDark: #eff2f5;
  --buttonColorDarker: #c5cfd9;
  --buttonColorDarkest: #607282;
  // --buttonDisabledBgColor: #c5cfd9;
  --buttonDisabledBgColor: #e0e0e0;
  --buttonDisabledColor: #8b8b8b;

  //RADIO
  --radioBgColor: transparent;
  --radioColor: #267ee6;
  --radioDisabledBgColor: transparent;
  --radioDisabledColor: #e0e0e0;

  //CHECKBOX
  --checkboxColor: #267ee6;

  //METRICS
  --negChangeColor: #ff4b5c;
  --posChangeColor: #1eae98;

  //OTHERS
  --popoverBgColor: #ffffff;
  --failureColor: #f44336;
  --overlayBgColor: rgba(0, 0, 0, 0.5);
  --overlayBgColorLight: rgba(0, 0, 0, 0.1);
}
