.ag-theme-alpine {
  font-family: Fira Sans !important;
}
.ag-theme-alpine .ag-header {
  background-color: white;
}

/* FOR white UNIFORM BACKGROUND(REMOVE ALTERED COLOR) */
/* .ag-theme-alpine .ag-row {
  background-color: white;
} */

.ag-root {
  font-family: Fira Sans !important;
}
.ag-root-wrapper {
  height: 100%;
  border: none !important;
  /* border-top: 1px solid var(--secondaryColorLightest) !important; */
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

/* SCROLLBAR STYLES */

/* width */
::-webkit-scrollbar {
  width: 5px;
  min-width: none;
  max-width: none;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px var(--secondaryColor); */
  border-radius: 10px;
  height: 5px;
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondaryColorLighter);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondaryColorLight);
}

.ag-body-viewport.ag-layout-normal {
  overflow-y: hidden;
}
.ag-body-viewport.ag-layout-normal:hover {
  overflow-y: auto;
}
.ag-body-horizontal-scroll {
  display: none;
  /* display: flex !important; */
}
.ag-horizontal-left-spacer {
  border: none !important;
}
/* HORIZONTAL FAKE SCROLLBAR IMPLEMENTATION 1 */
/* .ag-body-viewport.ag-layout-normal:hover ~ .ag-body-horizontal-scroll {
  display: flex;
} */
/* HORIZONTAL FAKE SCROLLBAR IMPLEMENTATION 2 */
.ag-root.ag-layout-normal:hover > .ag-body-horizontal-scroll {
  display: flex;
}

/* HORIZONTAL SCROLL */

/* .ag-body-horizontal-scroll {
  width: 5px;
  min-height: 5px;
  max-height: 5px;
} */

/* .header-row-column {
  height: "20px";
} */
/* .ag-header {
  height: 35px !important;
  min-height: 35px !important;
} */
/* .ag-row-even {
  height: 30px !important;
}
.ag-row-odd {
  height: 30px !important;
} */

.ag-root-wrapper {
  border: none;
  /* border-color: var(--ag-border-color, #babfc7) !important; */
}

.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-borders-row: none;
  --ag-row-border-color: transparent;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: none;
  line-height: min(var(--ag-line-height, 40px), 40px);
  /* padding-left: 17px;
  padding-right: 17px; */
  -webkit-font-smoothing: subpixel-antialiased;
}

.ag-theme-alpine
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border-right: none;
}

.ag-theme-alpine .ag-pinned-left-header {
  border-right: none;
}

.ag-theme-alpine .ag-header {
  border-bottom: none;
}

.ag-theme-alpine .ag-filter-toolpanel-header,
.ag-theme-alpine .ag-filter-toolpanel-search,
.ag-theme-alpine .ag-status-bar,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-panel-title-bar-title,
.ag-theme-alpine .ag-multi-filter-group-title-bar {
  font-weight: 600;
  color: var(--secondaryColor);
}

.ag-theme-alpine .ag-row {
  font-size: 11px;
  color: var(--secondaryColorLight);
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 12px;
  padding-right: 10px;
}
.ag-theme-alpine .ag-header-cell::after,
.ag-theme-alpine .ag-header-group-cell::after {
  border: none !important;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 12px;
  padding-right: 10px;
}

.ag-center-cols-clipper {
  transform: translateX(-3px);
}
.ag-pinned-left-cols-container {
  z-index: 1;
}
