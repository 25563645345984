@import "../../../styles/sass/variables.scss";

.dialog-alert-container {
  padding: 5px 15px;
  width: 300px;

  .dialog-alert-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bolder;
    font-family: "Fira Sans", sans-serif;
    color: $primaryColor;
    margin: 14px 0px !important;
    word-break: break-word;
  }

  .dialog-alert-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $secondaryColor;
    font-family: "Fira Sans", sans-serif;
    margin: 12px 0px !important;
  }

  .dialog-alert-actions-bar {
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 5px 0px;

    &.with-border {
      border-top: 1px solid $secondaryColorLighter;
      padding: 10px 0px 5px 0px;
    }
  }
}
