@import "../../../styles/sass/variables.scss";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $headerBgColor;
  box-shadow: $headerBoxShadow;
  z-index: 1;

  .left-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .client-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $sidenavWidth;

      .client-logo {
        width: calc(#{$sidenavWidth} - 4px);
        height: $headerHeight;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain; /* Resize the background image to cover the entire container */
      }
    }
  }

  .right-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $masterPadding;
  }
}
