@import "../../../styles/sass/variables.scss";

.user-info-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: $primaryColor;
  color: white;
  font-size: 10px;
  text-transform: capitalize;
  cursor: pointer;

  &.large {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
}

.user-info-pop-up-container {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  border-radius: 3px;
  background-color: $userInfoBgColor;

  .user-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    border-bottom: 1px solid $secondaryColorLightest;

    .user-email {
      text-transform: none;
      font-size: 9px;
      padding: 7px;
      color: $secondaryColor;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pop-up-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 3px 10px !important;
    font-size: 11px;
    height: 17px !important;
    color: $secondaryColor;

    &.disabled {
      color: $secondaryColorLighter !important;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      // color: $secondaryColor;
      background-color: $secondaryColorLightest;
    }

    .pop-up-item-title {
      margin: 0px !important;
      text-transform: capitalize;
    }

    .pop-up-item-icon {
      font-size: 15px !important;
      margin-left: 8px;

      &.hidden {
        display: none;
      }

      //Specific CSS
      &.toggle-off-icon {
        font-size: 19px !important;
      }
    }
  }
}
