@import "../../../styles/sass/variables.scss";

aside.sidenav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .sidenav-item-link {
    text-decoration: none;
    width: 100%;
  }

  .sidenav-item {
    padding: 7px 0px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    .sidenav-active {
      color: $primaryColor !important;
    }

    .sidenav-item-title {
      font-size: 8px;
      color: $sidenavItemColor;
      padding: 6px 0px 0px 0px;
    }

    .admin-custom-home-icon,
    .admin-custom-filter-icon,
    .admin-custom-report-icon,
    .admin-custom-alert-icon {
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
    .admin-custom-group-icon {
      color: $sidenavItemColor;
    }
    .admin-custom-user-icon {
      color: $sidenavItemColor;
    }

    .admin-custom-home-icon {
      background-image: url("../../../assets/images/home.svg");

      &.sidenav-active {
        background-image: url("../../../assets/images/homeHovered.svg");
      }
    }

    .admin-custom-filter-icon {
      background-image: url("../../../assets/images/filter.svg");
    }

    .admin-custom-report-icon {
      background-image: url("../../../assets/images/report.svg");
    }

    .admin-custom-alert-icon {
      background-image: url("../../../assets/images/alert.svg");
    }

    &:hover {
      background-color: $mainContentBgColor;

      .sidenav-item-title {
        color: $primaryColor;
      }
      .admin-custom-group-icon {
        color: $primaryColor;
      }
      .admin-custom-user-icon {
        color: $primaryColor;
      }

      .admin-custom-home-icon {
        background-image: url("../../../assets/images/homeHovered.svg");
      }
      .admin-custom-filter-icon {
        background-image: url("../../../assets/images/filterHovered.svg");
      }
      .admin-custom-report-icon {
        background-image: url("../../../assets/images/reportHovered.svg");
      }
      .admin-custom-alert-icon {
        background-image: url("../../../assets/images/alertHovered.svg");
      }
    }
  }

  &.dark {
    .sidenav-item {
      .admin-custom-home-icon {
        background-image: url("../../../assets/images/home.svg");
      }

      .admin-custom-filter-icon {
        background-image: url("../../../assets/images/filter.svg");
      }

      .admin-custom-report-icon {
        background-image: url("../../../assets/images/report.svg");
      }

      .admin-custom-alert-icon {
        background-image: url("../../../assets/images/alert.svg");
      }

      &:hover {
        background-color: $mainContentBgColor;

        .sidenav-item-title {
          color: $primaryColor;
        }
        .admin-custom-home-icon {
          // background-image: url("../../../assets/images/homeHoveredDark.svg");
          background-image: url("../../../assets/images/homeHovered.svg");
        }
        .admin-custom-filter-icon {
          // background-image: url("../../../assets/images/filterHoveredDark.svg");
          background-image: url("../../../assets/images/filterHovered.svg");
        }
        .admin-custom-report-icon {
          // background-image: url("../../../assets/images/reportHoveredDark.svg");
          background-image: url("../../../assets/images/reportHovered.svg");
        }
        .admin-custom-alert-icon {
          // background-image: url("../../../assets/images/alertHoveredDark.svg");
          background-image: url("../../../assets/images/alertHovered.svg");
        }
      }
    }
  }
}
