@import "../../../styles/sass/variables.scss";

.error-handler-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .error-handler-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $secondaryColor;
    font-size: 12px;
    text-align: center;
    margin: 0px !important;
    line-height: 18px;
  }

  .error-handler-reload-icon {
    border: 1px solid $primaryColor;
    border-radius: 3px;
    color: $primaryColor;
    font-size: 20px !important;
    padding: 2px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background-color: $primaryColor;
      border: 1px solid $primaryColor;
      color: $buttonColor;
    }
  }
}
