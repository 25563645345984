@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");

.alert-manager-container-overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding: 50px;
  box-sizing: border-box;
}
.alert-manager-form {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.alert-manager-form {
  .title-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 4px 16px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 0px solid var(--secondaryColorLighter);
  border-radius: 4px 4px 0px 0px;
  .left-panel {
    display: flex;
    align-items: center;
    padding-Bottom: 6px;
  }
}
}

.close-icon {
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
  color: var(--secondaryColor);
  padding: 5px;
}
.close-icon:hover {
  background-color: var(--secondaryColorLightest);
}
.content-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 40px);
  .left-panel {
    width: 240px;
    height: 100%;
    border-radius: 0px 0px 0px 4px;
    background: var(--popoverBgColor);
    border-right: 0px solid var(--secondaryColorLighter);
    box-sizing: border-box;
  }
}

.alert-manager-form {
  .content-container {
    .right-panel {
      width: calc(100% - 240px);
      // width: 100%;
      height: 100%;
      border-radius: 0px 0px 4px 0px;
      background: #f5f7ff;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
}

.no-alert-selected-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-or-open-alert-container {
  padding: 30px 50px;
  //background-color: var(--secondaryColorLightest);
  border-radius: 5px;
  //border: 1px dashed var(--secondaryColorLight);
  //box-shadow: var(--dsItemBoxShadow);
}
.no-alert-selected-text {
  color: var(--secondaryColor);
  margin: 10px 0px 0px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
}
.lower {
  padding: 4.5px 0;
}
.add-alert-button-container {
  display: flex;
  justify-content: center;
  padding: 0px 16px 10px 16px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  height: 60px;
}
.form {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/* Nav for Details and summary */
/* .content-nav-bar {
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  box-sizing: content-box !important;
} */

.container {
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 12px;
}
.empty-container {
  width: 70%;
  height: 3px;
  border-radius: 10px;
  background-color: #267ee6;
}

.content-nav-bar-container {
  height: 28px;
  display: flex;
  padding: 10px;
  box-sizing: content-box !important;
}
.content-main {
  height: calc(100% - 90px);
  box-sizing: border-box;
  overflow: auto !important;
  padding: 15px 5px 15px 20px;
}
/* footer styles */
.content-menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-menu-bar .left-panel-footer,
.right-panel-footer {
  height: 40px;
  box-sizing: border-box;
  background: var(--popoverBgColor);
  border-radius: 0px 0px 4px 0px;
  display: flex;
  align-items: center;
}

.right-panel-footer {
  justify-content: flex-end;
  padding-right: 5px;
  background: #f5f7ff;
}
/* footer styles ends */

/* scrollbar style */
// *::-webkit-scrollbar {
//   width: 1px;
// }

// *::-webkit-scrollbar-track {
//   background: #ffffff;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: var(--secondaryColorLighter);
//   border-radius: 11px;
// }

/* alert me metric rows style */
.alert-me-input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--secondaryColorLightest);
  padding: 11px !important;

  border-radius: 3px;
  box-sizing: border-box;
}
.alert-me-input-container > * {
  padding: 0 5px;
}

.alert-me-input-container > *:first-child {
  padding-left: 0 !important;
}

/* row visible in metric alert type */
.metric-criteria-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  flex-direction: column;
}
.and-or-container {
  padding: 0 11px;
}

.or-container {
  background-color: var(--secondaryColorLightest);
}

/* metric row delete $ add button  */
.metric-criteria-row-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.delete-btn {
  color: var(--secondaryColor);
  background-color: var(--popoverBgColor);
  border-radius: 3px;
  font-size: 16px !important;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.add-new-row-btn {
  background-color: var(--primaryColor);
  color: var(--popoverBgColor);
  border-radius: 3px;
  font-size: 16px !important;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.add-new-row-btn:hover {
  cursor: pointer;
  background-color: var(--primaryColor);
    color: var(--popoverBgColor);
}
.delete-btn {
  color: var(--secondaryColorLight);
  width: 24px;
  height: 24px;
}
.delete-btn:hover {
  cursor: pointer;
  color: var(--failureColor);
}
/*To hide percentage sign when user select Total or absolute change */
.no-percent-sign {
  visibility: hidden;
}

/* alert list css style */
.is-currently-selected-class {
  background-color: var(--secondaryColorLightest);
}
.alert-row-left-panel {
  width: calc(100% - 40px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 30px;
  justify-content: space-between;
}
.alert-row-right-panel {
  width: 40px;
  align-items: center;
}
.alert-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 3px;
  cursor: pointer;
  padding: 7px 16px;
}
.alert-row:hover {
  background-color: var(--primaryColorLightest);
}

.alert-sidenav {
  height: calc(100% - 60px);
}

.alert-sidenav-title {
  box-sizing: border-box;
  height: 100%;
}

.alert-sidenav-list {
  height: 97%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.alert-sidenav-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

.alert-sidenav-list::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: var(--secondaryColorLighter);
  border-radius: 5px;

  &:hover {
    background-color: var(--secondaryColorLight);
  }
}
.selectedFilter{
  width: 35px;
  margin-top: 3px;
  margin-bottom: 3px;
}
.filterBox{
  display: flex;
  align-items: center;
  border: 0px solid var(--secondaryColorLighter) !important;
  background: #fff;
}
.add-Alertfilters-icon{
  margin-left: 5px;
}
// click to add filters css
.input-item-container {
  // background-color: var(--secondaryColorLightest);
  border: 1px solid #edf1ff;
  border-radius: 3px;
  box-sizing: border-box;

  .info-title {
    font-size: 11px;
    font-family: inherit;
    color: var(--secondaryColorLight) !important;
    margin: 0px !important;
    // height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.add-filters-text {
      cursor: pointer;
      padding: 7px;
      color: var(--primaryColor) !important;
      background: #fff;
    }
  }
}
